import { useGroupV2 } from '../../contexts/Group/useGroupV2';
import { useUser } from '../../../../common/context/user/useUser';
import { GroupMember } from '../../../../common/api/model/GroupMember/GroupMember';

export function useGroupMember() {
  const { myMember } = useUser();
  const { groupV2, membership } = useGroupV2();
  const groupMember = new GroupMember(myMember!, { groupV2, membership });

  return {
    isMe(id: string) {
      return myMember?.id === id;
    },
    groupMember,
  };
}

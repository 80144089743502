import { useGroupV2 } from '../../contexts/Group/useGroupV2';
import { useBi } from '@wix/yoshi-flow-editor';
import {
  followMember,
  groupCreatePostClick,
  groupsMemberRequestApproveDecline,
  memberTabAdminActionClick,
  memberTabAdminActionDone,
} from '@wix/bi-logger-groups/v2';
import type {
  groupsMemberRequestApproveDeclineParams,
  memberTabAdminActionClickParams,
  memberTabAdminActionDoneParams,
  followMemberParams,
} from '@wix/bi-logger-groups/v2/types';
import { BIUserEntry } from '../../../../common/bi-logger/types';

export function useGroupBI() {
  const { groupV2 } = useGroupV2();
  const bi = useBi();

  return {
    groupCreatePostClick(biOrigin: string) {
      try {
        bi.report(
          groupCreatePostClick({
            groupId: groupV2?.id,
            origin: biOrigin,
          } as any),
        );
      } catch (e) {
        console.log('Bi Error');
      }
    },
    groupsMemberRequestApproveDecline({
      type,
      memberId,
    }: groupsMemberRequestApproveDeclineParams) {
      bi.report(
        groupsMemberRequestApproveDecline({
          type,
          group_id: groupV2?.id,
          isAll: false,
          memberId,
          origin: 'user_lvl_btn',
        } as any),
      );
    },
    memberTabAdminActionDone({
      site_member_id,
    }: Pick<memberTabAdminActionDoneParams, 'site_member_id' | 'action'>) {
      bi.report(
        memberTabAdminActionDone({
          group_id: groupV2?.id!,
          action: 'set_admin',
          site_member_id,
          origin: 'dialog_screen_btn_clk',
          userEntry: BIUserEntry.SITE,
        }),
      );
    },
    memberTabAdminActionClick({
      site_member_id,
    }: Pick<memberTabAdminActionClickParams, 'site_member_id'>) {
      return function (action: memberTabAdminActionClickParams['action']) {
        bi.report(
          memberTabAdminActionClick({
            group_id: groupV2?.id!,
            action,
            site_member_id,
            origin: 'member_tab_member_option_clk',
            userEntry: BIUserEntry.SITE,
          }),
        );
      };
    },
    followMember({
      site_member_id,
      action,
    }: Pick<followMemberParams, 'site_member_id' | 'action'>) {
      bi.report(
        followMember({
          group_id: groupV2?.id!,
          site_member_id,
          origin: 'discussion_tab_link_clk',
          userEntry: BIUserEntry.SITE,
          action,
        }),
      );
    },
  };
}

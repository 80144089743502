import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { settingsParams } from '../../Settings/settingsParams';
import { CoverImageLayout } from '../../Settings/settingsConstants';
import { stylesParams } from '../../Settings/styles';

function getImageWidth(mobile: boolean, coverImageLayout: CoverImageLayout) {
  return mobile ? 320 : coverImageLayout === CoverImageLayout.large ? 940 : 100;
}

function getImageHeight(
  mobile: boolean,
  coverImageLayout: CoverImageLayout,
  stylesHeight?: number,
) {
  if (mobile) {
    return stylesHeight || 240;
  }
  if (coverImageLayout === CoverImageLayout.large) {
    return stylesHeight || 240;
  }
  return 88;
}

export const useGroupSettings = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const styles = useStyles();

  const settingsKeyFor = getSettingsKeyFor('coverImageLayout', isMobile);
  const coverImageLayout = settings.get(settingsParams[settingsKeyFor]);

  const targetWidth = getImageWidth(isMobile, coverImageLayout);
  let targetHeight: number;
  {
    const key = getSettingsKeyFor('coverImageHeight', isMobile);
    const h = styles.get(stylesParams[key]);
    targetHeight = getImageHeight(isMobile, coverImageLayout, h);
  }
  const withImage = coverImageLayout !== CoverImageLayout.blank;

  return { coverImageLayout, withImage, targetWidth, targetHeight };
};

import React from 'react';
import { st, classes } from './Placeholder.st.css';

interface PlaceholderCardProps {
  children?: React.ReactNode;
}

interface PlaceholderTitleProps extends PlaceholderCardProps {
  className?: string;
}

export const PlaceholderTitle: React.FC<PlaceholderTitleProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div className={st(classes.root, { empty: !children }, rest.className)}>
      <div className={classes.wrapper}>
        <span className={classes.placeholder}>{children}</span>
      </div>
    </div>
  );
};
PlaceholderTitle.displayName = 'PlaceholderTitle';

export const PlaceholderAvatar: React.FC<PlaceholderCardProps> = (props) => {
  return <div className={st(classes.root, {})}>{props.children}</div>;
};
PlaceholderAvatar.displayName = 'PlaceholderAvatar';

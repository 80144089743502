import React from 'react';
import { CommonErrorProps } from './CommonErrorProps';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { EmptyState } from '../EmptyState/EmptyState';

export const Error500: React.FC<CommonErrorProps> = (props) => {
  const { t } = useTranslation();
  const { isOwner, action } = props;
  return (
    <EmptyState
      title={t('groups-web.discussion.error-500.title')}
      content={t(
        isOwner
          ? 'groups-web.discussion.error-500.content.owner'
          : 'groups-web.discussion.error-500.content.user',
      )}
      button={{
        label: t(
          isOwner
            ? 'groups-web.discussion.error-500.cta.owner'
            : 'groups-web.discussion.error-500.cta.user',
        ),
        onClick: () => {
          if (isOwner) {
            window.location.replace(
              'https://www.wix.com/support/conversations/category/contact',
            );
            return;
          }
          return action();
        },
      }}
    />
  );
};

Error500.displayName = 'Error500';

import React from 'react';
import classname from 'classnames';

import {
  canInviteMembers,
  canSeeGroup,
} from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { Text } from 'wix-ui-tpa/Text';

import { MembersList } from './MembersList';
import { MembersWidgetCTAButton } from './MembersWidgetCTAButton';

import * as discussionStyles from '../Discussion.st.css';
import { classes } from './MembersWidget.st.css';

import { GroupApps } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupApps';
import {
  InjectedExperimentsProps,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import { Box } from '../../../../../common/components/Box/Box';
import { getMembersLabel } from '../../MembersLabel/MembersLabel';
import { compose } from '../../../../../common/utils/compose';
import { LoginView } from '../../../../../common/components/LoginView/LoginView';
import { LoginButton, LoginText } from './LoginComponents';

type Props = WithTranslation &
  WithGroupProps &
  WithSiteMembers &
  InjectedExperimentsProps;

class MembersWidgetComponent extends React.Component<Props> {
  groupApps: GroupApps; // TODO: remove this
  constructor(props: Props) {
    super(props);

    this.groupApps = GroupApps.fromAppsMap(props.apps);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.apps !== this.props.apps) {
      this.groupApps = GroupApps.fromAppsMap(this.props.apps);
    }
  }

  static displayName = 'MembersWidgetComponent';

  render() {
    const { group } = this.props;
    if (!canSeeGroup(group)) {
      return null;
    }

    if (!this.groupApps.isMembersTabInstalled()) {
      return null;
    }

    const membersTitle = this.getMembersWidgetTitle();

    return (
      <Box article className={classes.root} data-hook="members-widget">
        <Text
          className={classname(
            discussionStyles.classes.widgetHeader,
            classes.header,
          )}
        >
          {membersTitle}
        </Text>

        <LoginView login={LoginText}>
          <MembersList />
        </LoginView>

        <div className={classes.ctaButtonWrapper}>
          <LoginView login={LoginButton}>
            <MembersWidgetCTAButton />
          </LoginView>
        </div>
      </Box>
    );
  }

  getMembersWidgetTitle = () => {
    const { t, group } = this.props;
    const { key, value } = getMembersLabel(group, t);
    const addMembersTitle = t(`${key}.add-widget.title`, {
      membersLabel: value,
    });
    return canInviteMembers(group) ? addMembersTitle : value;
  };
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withSiteMembers,
  withExperiments,
);

export const MembersWidget = enhance(
  MembersWidgetComponent,
) as React.ComponentType;

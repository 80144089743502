import React from 'react';
import { Error403 } from './Error403';
import { Error500 } from './Error500';
import { CommonErrorProps } from './CommonErrorProps';
import { ErrorOrigin } from '../../controllers/errorHandler/IErrorEvent';
import { ErrorHandlerContext } from '../../contexts/ErrorHandler/ErrorHandlerContext';

interface ErrorProps {
  origin: ErrorOrigin;
  isOwner: boolean;
}

function getComponentForErrorCode(code: number, errProps: CommonErrorProps) {
  if (code === 403) {
    return <Error403 {...errProps} />;
  }
  if (500 <= code && code <= 599) {
    return <Error500 {...errProps} />;
  }
  return null;
}

export const UIError: React.FC<ErrorProps> = (props) => {
  const { children, origin, isOwner } = props;
  const { errorEvents, errorHandlers } = React.useContext(ErrorHandlerContext);
  const errorEvent = errorEvents && errorEvents[origin];
  if (errorEvent) {
    const action = () => errorHandlers.actionByError(errorEvent);
    return getComponentForErrorCode(errorEvent.code, { action, isOwner });
  }

  return (
    <div className="uiError" key="uiError">
      {children}
    </div>
  );
};

UIError.displayName = 'UIError';

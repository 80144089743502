import React from 'react';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { TextField } from 'wix-ui-tpa/TextField';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Tag, SIZE } from 'wix-ui-tpa/Tag';
import { Popover, TriggerAction } from 'wix-ui-tpa/Popover';

import { isAdmin } from '@wix/social-groups-api';
import { useArrayUpdates } from './useArrayUpdates';
import { st, classes } from './FeedTopics.st.css';
import {
  useBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { AddIcon } from '../../icons/AddIcon';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { ITopic } from '../../../types/ITopic';
import {
  groupFeedTopicsAddTopicClickIntent,
  groupFeedTopicsTopicActions,
} from '@wix/bi-logger-groups/v2';
import { withTheme, Theme } from '../../../../../common/context/theme';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';

export interface FeedTopicsProps {
  topicId: string;
  feedItemId?: string;
  isEditable?: boolean;

  onTopicChange(topicId: string): void;
}
const PopoverElement = Popover.Element!;
const PopoverContent = Popover.Content!;
const FeedTopicsComponent: React.FC<FeedTopicsProps & WithTranslation & Theme> =
  (props) => {
    const {
      topicId,
      feedItemId,
      onTopicChange,
      isEditable,
      t,
      forceBlackAndWhite,
    } = props;
    const { feed, group } = React.useContext(GroupContext);
    const bi = useBi();
    const topics = feed.feedTopics;

    const canCreateTopics = React.useRef(isAdmin(group));
    const [search, setSearch] = React.useState<string>();
    const [inProgress, setInProgress] = React.useState(false);
    const [shown, setShown] = React.useState(false);

    const filtered = React.useMemo(filter, [search, topics.length, topics]);
    const topic = React.useMemo(findById, [topicId, topics.length, topics]);

    const { startWatching } = useArrayUpdates(selectLastCreatedTopic, topics);

    if (topic) {
      return (
        <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
          <Tag
            className={classes.tag}
            isRemovable={isEditable}
            onRemove={isEditable ? () => clearTopic(topic) : undefined}
          >
            {topic.displayName}
          </Tag>
        </div>
      );
    }

    return (
      <Popover
        wiredToSiteColors={!forceBlackAndWhite}
        placement="top-start"
        className={st(classes.root, { bw: !!forceBlackAndWhite })}
        triggerAction={TriggerAction.click}
        shown={shown}
      >
        <PopoverElement>
          <TextButton
            forceBlackAndWhite={forceBlackAndWhite}
            onClick={() => setShown(!shown)}
            priority={TEXT_BUTTON_PRIORITY.secondary}
            prefixIcon={<AddIcon width="24px" height="24px" />}
          >
            {t('groups-web.discussion.topics.modal.open-button')}
          </TextButton>
        </PopoverElement>
        <PopoverContent>
          <div className={st(classes.content)}>
            <TextField
              className={classes.input}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t(
                'groups-web.discussion.topics.modal.search.placeholder',
              )}
              maxLength={40}
            />
            <Text
              tagName="p"
              className={st(classes.label)}
              typography={TYPOGRAPHY.runningText}
            >
              {t(getStatusLabel())}
            </Text>
            <ul className={st(classes.topicList)}>
              {filtered.map((_topic) => (
                <Tag
                  key={_topic.id}
                  className={st(classes.topic, {}, classes.tag)}
                  size={SIZE.small}
                  onClick={() => selectTopic(_topic)}
                >
                  {`${_topic.displayName} (${_topic.count})`}
                </Tag>
              ))}
              {canCreateTopics.current && search && (
                <li className={st(classes.topic)}>
                  <TextButton
                    forceBlackAndWhite={forceBlackAndWhite}
                    disabled={inProgress}
                    priority={TEXT_BUTTON_PRIORITY.secondary}
                    onClick={() => createTopic(search)}
                    prefixIcon={<AddIcon width="24px" height="24px" />}
                  >
                    {t('groups-web.discussion.topics.modal.create-button')}
                  </TextButton>
                  &nbsp;&nbsp;
                  <Tag size={SIZE.small} className={classes.tag}>
                    {search}
                  </Tag>
                </li>
              )}
            </ul>
          </div>
        </PopoverContent>
      </Popover>
    );

    function getStatusLabel() {
      if (!topics.length) {
        return canCreateTopics.current
          ? `groups-web.discussion.topics.modal.status-label.empty.admin`
          : 'groups-web.discussion.topics.modal.status-label.empty.member';
      }

      if (!filtered.length) {
        return 'groups-web.discussion.topics.modal.status-label.filters-empty';
      }

      return canCreateTopics.current
        ? 'groups-web.discussion.topics.modal.status-label.admin'
        : 'groups-web.discussion.topics.modal.status-label.member';
    }

    async function createTopic(topicName: string) {
      setInProgress(true);
      await feed.createPostTopic(topicName);
      setInProgress(false);

      startWatching();
      bi.report(
        groupFeedTopicsAddTopicClickIntent({
          origin: 'topics_list_plus_btn_in_post',
          groupId: group.groupId!,
          userEntry: BIUserEntry.SITE,
        }),
      );
    }

    function clearTopic(_topic: ITopic) {
      handleSelect(null as any);
      bi.report(
        groupFeedTopicsTopicActions({
          action: 'delete',
          origin: feedItemId ? 'post_creation' : 'post_to_the_topic',
          topicName: _topic.displayName,
          topicId: _topic.id,
          groupId: group.groupId!,
          userEntry: BIUserEntry.SITE,
        }),
      );
    }

    function selectLastCreatedTopic() {
      selectTopic(topics[topics.length - 1]);
    }

    function selectTopic(_topic: ITopic) {
      handleSelect(_topic.id!);

      if (feedItemId) {
        bi.report(
          groupFeedTopicsTopicActions({
            action: 'edit',
            origin: 'post_to_the_topic',
            topicName: _topic.displayName,
            topicId: _topic.id,
            groupId: group.groupId!,
            userEntry: BIUserEntry.SITE,
          }),
        );
      } else {
        bi.report(
          groupFeedTopicsAddTopicClickIntent({
            origin: 'topics_list_in_post',
            groupId: group.groupId!,
            userEntry: BIUserEntry.SITE,
          }),
        );
      }
    }

    function handleSelect(_topicId: string) {
      onTopicChange(_topicId);
      setSearch(undefined);
    }

    function filter() {
      const pattern = new RegExp(search!, 'i');
      return topics.filter((_topic) => pattern.test(_topic.displayName!));
    }

    function findById() {
      return topics.find((_topic) => _topic.id === topicId);
    }
  };

export const FeedTopics = withTheme(
  withTranslation()(FeedTopicsComponent),
) as any;

import { groupCoverUrl } from '../../../../common/utils/groupCoverUrl';
import { IGroup } from '../../../../common/types/IGroup';

export interface RepositioningLogo {
  logoUrl?: string;
  height?: number;
  focalPointY?: number;
}
export function getRepositioningLogo(
  g: Pick<IGroup, 'logo' | 'getScaledLogo' | 'logoPosition'>,
  scale: { targetWidth: number; targetHeight: number },
): RepositioningLogo {
  const { targetHeight, targetWidth } = scale;
  const scaledHeightWithSaveRatio =
    (targetWidth / (g.logo.width || targetWidth)) *
    (g.logo.height || targetHeight);

  return {
    logoUrl: groupCoverUrl(g, {
      targetWidth,
      targetHeight: scaledHeightWithSaveRatio,
    }),
    focalPointY: g.logoPosition.y == null ? 50 : g.logoPosition.y,
    height: scaledHeightWithSaveRatio,
  };
}

export function getParam(param: string) {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  } catch (e) {
    console.log('[getParam] Failed to get param from window.location.search');
  }
}

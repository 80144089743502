import React from 'react';
import { IGroupV2 } from './IGorupV2';
import { ProviderProps } from '@wix/social-groups-api/dist/src/context/ProviderProps';
import { getContextFromProps } from '@wix/social-groups-api/dist/src/context/getContextFromProps';

const defaultGroupV2Context: IGroupV2 = {
  groupV2: null,
  membership: null,
};
export const GroupV2Context = React.createContext<IGroupV2>(
  defaultGroupV2Context,
);

export const GroupV2ContextProvider: React.FC<ProviderProps<IGroupV2>> = ({
  children,
  value,
}) => {
  const _value = getContextFromProps(value, defaultGroupV2Context);
  return (
    <GroupV2Context.Provider value={_value}>{children}</GroupV2Context.Provider>
  );
};

GroupV2ContextProvider.displayName = 'GroupV2ContextProvider';

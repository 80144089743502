import React, { useMemo } from 'react';
import { classes } from './AddMembersModal.st.css';
import { Search, SearchProps } from '../../../../../common/components/Search';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

interface Props extends SearchProps {
  count: number;
  isSecret: boolean;
}
export const SearchBar: React.FC<Props> = (props) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { count, isSecret, ...rest } = props;
  const placeholder = useMemo(() => {
    let key = '';
    if (isSecret) {
      key = 'groups-web.secret-group.add.members.search.placeholder';
    } else {
      key = count
        ? 'groups-web.add.members.search.placeholder'
        : 'groups-web.add.members.search.no-site-members.placeholder';
    }
    return t(key, { count });
  }, [count, isSecret]);
  return (
    <div className={classes.searchWrapper}>
      <Search
        {...rest}
        className={classes.search}
        withBorder={!isMobile}
        fullWidth={true}
        forceOpen={true}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchBar.displayName = 'SearchBar';

import React from 'react';

import { ApiTypes } from '@wix/social-groups-api';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/';

import classnames from 'classnames';

import moment from 'moment';

import * as activityStyles from './GroupActivity.st.css';
import { st, classes } from '../About.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import {
  withActivity,
  WithActivityProps,
} from '../../../contexts/Activity/withActivity';
import {
  NewMembersProps,
  withNewMembers,
} from '../../../contexts/SiteMembers/withNewMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';

import {
  InfoCard,
  InfoCardProps,
} from '../../../../../common/components/Cards/InfoCard';
import { getMembersLabel } from '../../MembersLabel/MembersLabel';
import { getSettingsKeyFor } from '../../../../../common/utils/utils';
import { Box } from '../../../../../common/components/Box/Box';
import { compose } from '../../../../../common/utils/compose';
import { Header } from '../../../../../common/components/Text/Header';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../Settings/settingsParams';
import { GROUP_ACTIVITY_INFO_CARD } from 'Group/Widget/About/dataHooks';

interface GroupProps {
  group: ApiTypes.v1.GroupResponse;
}

export interface GroupActivityProps
  extends WithTranslation,
    WithActivityProps,
    GroupProps,
    NewMembersProps,
    WithTpaComponentsConfigProps,
    WithSettingsProps {
  group: ApiTypes.v1.GroupResponse;
  className?: string;
}

const GroupActivityCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  withPlaceholder,
}) => (
  <div className={activityStyles.classes.activityWrapper}>
    <InfoCard
      dataHook={GROUP_ACTIVITY_INFO_CARD}
      className={activityStyles.classes.activity}
      title={title}
      subtitle={subtitle}
      withPlaceholder={withPlaceholder}
      reverse
    />
  </div>
);

const GroupActivityDivider = () => (
  <div className={activityStyles.classes.divider}></div>
);

class GroupActivityComponent extends React.Component<GroupActivityProps> {
  componentDidMount(): void {
    if (canSeeGroup(this.props.group)) {
      this.getActivityStats();
    }
  }

  componentDidUpdate(prevProps: Readonly<GroupActivityProps>) {
    if (!canSeeGroup(prevProps.group) && canSeeGroup(this.props.group)) {
      this.getActivityStats();
    }
  }

  // TODO: call this when real reactions data will be provided
  renderReactionsStats() {
    const { t } = this.props;
    return (
      <>
        <GroupActivityDivider />
        <GroupActivityCard
          title="42"
          subtitle={t('groups-web.activity.reactions')}
        />
      </>
    );
  }

  render() {
    const { t, activities, group, newMembers, mobile, settings } = this.props;
    if (!canSeeGroup(group)) {
      return null;
    }
    const newPosts = activities && activities.newPosts;
    const membersLabel = getMembersLabel(group, t, newMembers);
    const showActivityKey = getSettingsKeyFor('showActivity', mobile!);
    const appSetting = settings.get(settingsParams[showActivityKey]);
    if (!appSetting) {
      return null;
    }

    return (
      <Box className={st(classes.root)}>
        <div
          className={classnames(
            classes.headerWrapper,
            mobile ? '' : activityStyles.classes.headerWrapper,
          )}
        >
          <Header className={classes.header}>
            {t('groups-web.activity')}:
            <span className={classes.faded}>
              {' '}
              {t('groups-web.lastNDays', { N: 30 })}
            </span>
          </Header>
        </div>
        <div
          className={activityStyles.st(
            activityStyles.classes.activities,
            { mobile } as any,
            this.props.className,
          )}
        >
          <GroupActivityCard
            title={this.isActivityValueFetched(newPosts) && `${newPosts}`}
            subtitle={t('groups-web.activity.posts', { count: newPosts })}
            withPlaceholder
          />
          <GroupActivityDivider />
          <GroupActivityCard
            title={this.isActivityValueFetched(newMembers) && `${newMembers}`}
            subtitle={t('groups-web.activity.members', {
              membersLabel: membersLabel.value,
              count: newMembers,
            })}
            withPlaceholder
          />
        </div>
      </Box>
    );
  }

  private isActivityValueFetched(value: any) {
    return value !== null && value >= 0;
  }

  private getActivityStats() {
    try {
      const { activityActions, getNewMembers, group } = this.props;
      activityActions.getActivity(group);
      getNewMembers(group, moment().subtract(1, 'months').toDate());
    } catch (e) {
      console.log('Get group activity: FAIL');
    }
  }
}

const enhance = compose(
  withNewMembers,
  withActivity,
  withTpaComponentsConfig,
  withSettings,
  withTranslation(),
);

export const GroupActivity: React.ComponentType<GroupProps> = enhance(
  GroupActivityComponent,
);

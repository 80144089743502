import { Filters } from '../../../types/FeedControllerProps';
import { TFunction } from '@wix/yoshi-flow-editor';
import { ITopic } from '../../../types/ITopic';
import { EFilterKeys } from '../../../types/EFilterKeys';

export function getBreadcrumbMap(t: TFunction) {
  return {
    [EFilterKeys.TOPICS]: (topics: ITopic[], filters: Partial<Filters>) => {
      const topicId = filters[EFilterKeys.TOPICS];
      const topic = topics.find((_topic) => _topic.id === topicId);

      return t('groups-web.discussion.filters.topics', {
        topicName: topic ? topic.displayName : '',
      });
    },
  };
}

export function hasFilters(filters: any) {
  return filters && Object.values(filters).some((value) => !!value);
}

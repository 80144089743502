import React from 'react';
import { LetterIcon } from '../../icons/LetterIcon';
import { classes } from './AddMembersModal.st.css';
import { MemberCard } from '../../MemberCard/MemberCard';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Theme, withTheme } from '../../../../../common/context/theme';

export interface InviteProps {
  email: string;
  done: boolean;
  invite(): void;
}

export const InvitesComponent: React.FC<InviteProps & Theme> = ({
  email,
  done,
  invite,
  forceBlackAndWhite,
}) => {
  const { t } = useTranslation();
  return (
    <MemberCard
      forceBlackAndWhite={forceBlackAndWhite}
      name={email}
      image={<LetterIcon className={classes.inviteIcon} />}
      done={done}
      onActionClick={invite}
      actionLabel={t('groups-web.invite')}
      actionDoneLabel={t('groups-web.invited')}
    />
  );
};

export const Invites = withTheme(
  InvitesComponent,
) as React.ComponentType<InviteProps>;
Invites.displayName = 'Invites';

import React from 'react';
import classnames from 'classnames';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { FileInput } from '../../../../../../common/components/FileInput/FileInput';
import { ImagePluginIcon } from '../../../icons/ImagePluginIcon';
import { VideoCameraIcon } from '../../../icons/VideoCamera';
import { GiphyPluginIcon } from '../../../icons/GiphyPluginIcon';
import { INSERT_PLUGIN_BUTTONS } from '../../../../../../common/ContentEditor/consts';

import { st, classes } from './InitialIntentToolbar.st.css';

interface InitialIntentToolbarProps {
  blockMode?: boolean;
  onIconClick?(event: any, type: string): any;
}

export const InitialIntentToolbar: React.FC<InitialIntentToolbarProps> = (
  props,
) => {
  const { onIconClick, blockMode } = props;
  const { t } = useTranslation();

  const defaultClassName = classnames(
    classes.fillPluginIcon,
    classes.pluginIcon,
  );

  const clickHandler = (type: string) => (e: any) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    onIconClick && onIconClick(e, type);
  };

  return (
    <div
      className={st(classes.root, {
        blockMode: !!blockMode,
      })}
    >
      <FileInput
        onChange={clickHandler(INSERT_PLUGIN_BUTTONS.IMAGE)}
        className={classes.pluginIconWrapper}
      >
        <ImagePluginIcon
          width={19}
          height={19}
          className={defaultClassName}
          key="Image"
        />
        <Text
          typography={TYPOGRAPHY.runningText}
          className={classes.pluginIconName}
        >
          {t('groups-web.discussion.new-post.plugins.image')}
        </Text>
      </FileInput>
      <div
        className={classes.pluginIconWrapper}
        onClick={clickHandler(INSERT_PLUGIN_BUTTONS.VIDEO)}
      >
        <VideoCameraIcon height={19} className={defaultClassName} key="Video" />
        <Text
          typography={TYPOGRAPHY.runningText}
          className={classes.pluginIconName}
        >
          {t('groups-web.discussion.new-post.plugins.video')}
        </Text>
      </div>
      <div
        className={classes.pluginIconWrapper}
        onClick={clickHandler(INSERT_PLUGIN_BUTTONS.GIF)}
      >
        <GiphyPluginIcon
          width={19}
          height={19}
          className={classnames(classes.strokePluginIcon, classes.pluginIcon)}
          key="Giphy"
        />
      </div>
    </div>
  );
};

InitialIntentToolbar.displayName = 'InitialIntentToolbar';

import React from 'react';
import { Logger } from '../../../../common/loggers';

export interface Loggers {
  logger: Logger;
}

export const LoggersContext = React.createContext<Loggers>({
  logger: undefined as any,
});

import React from 'react';
import { Grid } from 'wix-ui-tpa/Grid';

// API
import { canSeeFeed } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';
import * as IReactionsTypes from '@wix/ambassador-reactions-v1-reaction/types';

import { About } from './About';
import { Feed, FeedProps } from './Feed';
import { MembersWidget } from './MembersWidget';

// STYLES
import { classes, st } from './Discussion.st.css';

import { Navigation } from './Filters';
import { FeedTopicsWidget } from './FeedTopics';
import { EventsWidget } from '../Events/EventsWidget';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import { DETAILS_TITLE } from '../Details/dataHooks';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { UIError } from '../Error/UIError';
import { ErrorOrigin } from '../../controllers/errorHandler/IErrorEvent';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { compose } from '../../../../common/utils/compose';
import { EFilterKeys } from '../../types/EFilterKeys';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../Settings/settingsParams';
import {
  groupFeedTopicsAddTopicToPost,
  groupFeedView,
} from '@wix/bi-logger-groups/v2';
import { PaginationSeo } from './NextPage/PaginationSeo';
import { FEED } from './dataHooks';
import { WritePost } from './NewPost/WritePost';
import { EmptyStates } from './EmptyStates';

export interface DiscussionProps {
  feedItemId?: string;
  discussionUrl?: string;
}

type DiscussionComponentProps = WithTranslation &
  DiscussionProps &
  WithGroupProps &
  WithTpaComponentsConfigProps &
  WithSettingsProps &
  InjectedBiLoggerProps;

export class DiscussionComponent extends React.Component<DiscussionComponentProps> {
  componentDidUpdate(props: DiscussionComponentProps) {
    const isLoading = this.props.feed.feedLoading;

    // if previously was loading
    if (!isLoading && props.feed.feedLoading) {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const $title =
      document && document.querySelector(`[data-hook="${DETAILS_TITLE}"]`);

    if (!$title) {
      return;
    }

    Promise.resolve().then(() => {
      requestAnimationFrame(() => {
        $title.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    });
  }

  componentDidMount(): void {
    const { bi } = this.props;
    bi.report(
      groupFeedView({
        clubId: this.props.group.groupId!,
      }),
    );
  }

  getFeedEntity = (
    content: RawDraftContentState<any>,
    topicId?: string,
  ): IFeedTypes.FeedItemEntity => {
    return {
      body: {
        content: JSON.stringify(content),
        contentType: IFeedTypes.ContentType.DRAFTJS,
      },
      topics: topicId ? [topicId] : [],
    };
  };

  reactFeedItem = (feedItemId: string, reaction: IReactionsTypes.Reaction) => {
    this.props.feed.reactFeedItem(feedItemId, reaction);
  };
  // TODO: is it the same interface? if not - why not?

  unreactFeedItem = (feedItemId: string, reactionCode: string) => {
    this.props.feed.unreactFeedItem(feedItemId, reactionCode);
  };

  handleCreatePost = (content: any, topicId?: string) => {
    const { feed, bi, group } = this.props;
    feed.createFeedItem(this.getFeedEntity(content, topicId));

    if (topicId) {
      bi.report(
        groupFeedTopicsAddTopicToPost({
          groupId: group.groupId!,
          userEntry: BIUserEntry.SITE,
          origin: 'post_creation',
          topicName: topicId,
        }),
      );
    }
  };

  handleUpdatePost = (
    feedItemId: string,
    content: RawDraftContentState<any>,
    topicId?: string,
  ) =>
    this.props.feed.updateFeedItem(
      feedItemId,
      this.getFeedEntity(content, topicId),
    );

  handleDeletePost = (feedItemId: string) =>
    this.props.feed.deleteFeedItem(feedItemId);

  handlePinPost = (feedItemId: string) =>
    this.props.feed.pinFeedItem(feedItemId);

  handleUnpinPost = (feedItemId: string) =>
    this.props.feed.unpinFeedItem(feedItemId);

  handleFollowPost = (feedItemId: string) =>
    this.props.feed.followFeedItem(feedItemId);

  handleUnfollowPost = (feedItemId: string) =>
    this.props.feed.unfollowFeedItem(feedItemId);

  render() {
    const { group, feedItemId, mobile, discussionUrl, settings, apps } =
      this.props;
    const layoutSpacingKey = getSettingsKeyFor('layoutSpacing', mobile!);

    const layoutSpacing = settings.get(settingsParams[layoutSpacingKey]);
    const isGroupOwner = false; // TODO
    return (
      <UIError origin={ErrorOrigin.Feed} isOwner={isGroupOwner}>
        <link
          rel="canonical"
          href={feedItemId ? `${discussionUrl}/${feedItemId}` : discussionUrl}
        />
        <Grid
          maxColumns={mobile ? 1 : 3}
          columnGap={layoutSpacing}
          className={st(classes.root, { mobile } as any)}
        >
          <Grid.Item colSpan={mobile ? 1 : 2}>
            <div className={classes.container}>
              <Navigation />
              {this.renderContent()}
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className={classes.container}>
              <About group={group} apps={apps} />
              <MembersWidget />
              {!mobile ? (
                <>
                  <FeedTopicsWidget />
                  <EventsWidget />
                </>
              ) : null}
            </div>
          </Grid.Item>
        </Grid>
      </UIError>
    );
  }

  private renderContent() {
    const { group, feed, discussionUrl, mobile } = this.props;
    if (!canSeeFeed(group)) {
      return null;
    }
    const hideWritePost = feed.feedFilters && feed.feedFilters.feedItemId;
    return (
      <>
        {hideWritePost ? null : (
          <WritePost
            onCreatePost={this.handleCreatePost}
            topicId={feed.feedFilters[EFilterKeys.TOPICS]}
            feedRequest={feed.feedRequest}
          />
        )}
        {mobile ? <FeedTopicsWidget /> : null}
        {feed.cursor ? (
          <PaginationSeo
            url={discussionUrl!}
            query={{ cursor: feed.cursor, prevCursor: feed.prevCursor! }}
          />
        ) : null}
        {this.renderFeed()}
      </>
    );
  }

  private renderFeed() {
    const { feed, t } = this.props;
    if (feed.feedLoading) {
      return <Spinner offset="L" label={t('groups-web.discussion.loading')} />;
    }
    if (!feed.feedItems) {
      // TODO: smth went wrong
      return null;
    }
    // TODO: refactor for better debugging
    const postActions = {
      onDeletePostClick: this.handleDeletePost,
      onPinPostClick: this.handlePinPost,
      onUnpinPostClick: this.handleUnpinPost,
      onFollowPostClick: this.handleFollowPost,
      onUnfollowPostClick: this.handleUnfollowPost,
      onUpdatePostClick: this.handleUpdatePost,
      react: this.reactFeedItem,
      unreact: this.unreactFeedItem,
    };
    // TODO:why do we need this?
    const feedItems = feed.feedItems.filter(
      (
        feedItem, // activity post
      ) =>
        feedItem.activity || // user's post
        feedItem.entity?.body.contentType === IFeedTypes.ContentType.DRAFTJS,
    );
    // TODO: EmptyStates shows up after login
    return (
      <section className="feed" key="feed" data-hook={FEED}>
        {feedItems.length ? (
          <Feed
            {...(postActions as FeedProps)}
            hasMore={!!feed.cursor}
            fetchMore={feed.fetchMore}
            feedItems={feedItems}
            contextToken={feed.contextToken!}
          />
        ) : (
          <EmptyStates />
        )}
      </section>
    );
  }
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withTpaComponentsConfig,
  withSettings,
  withBi,
);

export const Discussion = enhance(
  DiscussionComponent,
) as React.ComponentType<DiscussionProps>;

export default Discussion;

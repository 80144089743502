import React from 'react';
import { ActionType } from './ActionType';

export interface IUserActionsContext {
  initAction(actionType: ActionType, origin?: string): Promise<void>;
  finishAction(): void;
  currentAction: ActionType | null;
}

const defaultActionsContext: IUserActionsContext = {
  finishAction(): void {},
  initAction(actionType: ActionType) {
    return Promise.resolve();
  },
  currentAction: null,
};

export const UserActionsContext = React.createContext<IUserActionsContext>(
  defaultActionsContext,
);

import React from 'react';
import { compose } from '../../../utils/compose';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../../components/Group/contexts/TPAComponent/withTpaComponentsConfig';
import { MobileRepositionLayout } from './MobileRepositionLayout';
import { RepositionControls } from './RepositionControls';
import { ClickOutside } from '../ClickOutside';

interface RepositionLayoutProps {
  loading: boolean;
  height: number;
  width: number;
  onPositionChange(movementY: number): void;
  onCancel(): void;
  onSave(): void;
  renderImage(): JSX.Element | null;
  rootRef: React.RefObject<any>;
}

type Props = RepositionLayoutProps & WithTpaComponentsConfigProps;

const RepositionLayoutComponent: React.FC<Props> = ({
  loading,
  onCancel,
  onSave,
  onPositionChange,
  renderImage,
  height,
  width,
  mobile,
  rootRef,
}) => {
  const repositionControls = () => (
    <RepositionControls
      loading={loading}
      onSave={onSave}
      onChange={onPositionChange}
      onCancel={onCancel}
    />
  );

  const mobileLayout = () => (
    <MobileRepositionLayout
      loading={loading}
      onCancel={onCancel}
      onSave={onSave}
    >
      <div
        style={{
          position: 'relative',
          height: `${height}px`,
          width: `${width}px`,
        }}
      >
        {renderImage()}
        {repositionControls()}
      </div>
    </MobileRepositionLayout>
  );

  const desktopLayout = () => (
    <ClickOutside rootRef={rootRef} onClickOutside={onCancel}>
      {repositionControls()}
    </ClickOutside>
  );

  return mobile ? mobileLayout() : desktopLayout();
};

const enhance = compose(withTpaComponentsConfig);
export const RepositionLayout = enhance(
  RepositionLayoutComponent,
) as React.ComponentType<RepositionLayoutProps>;

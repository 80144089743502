import React from 'react';
import { PagesContext } from './PagesContext';

interface PagesProps {
  activePage: string;
}

export const Pages: React.FC<PagesProps> = ({ children, activePage }) => {
  return (
    <PagesContext.Provider children={children || null} value={{ activePage }} />
  );
};

import { RestrictionType } from '@wix/social-groups-api/dist/src/model/Group/GroupAccessRestriction';
import { ApiTypes } from '@wix/social-groups-api';
import { PrivacyLabels, PrivacyMap } from '../Privacy/PrivacyMap';
import moment from 'moment';
import { isGroupPrivate } from '@wix/social-groups-api/dist/src/model/Group/privacyLevel';
import { VisibilityMap } from '../Privacy/VisibilityMap';

export const AccessRestrictionLabels = {
  [RestrictionType.EVENTS]: 'groups-web.restriction.events.label',
  [RestrictionType.ADMIN_APPROVAL]: 'groups-web.restriction.admin.label',
  [RestrictionType.PAID_PLANS]: 'groups-web.restriction.plans.label',
};

const getTimeFromCreateDate = (d: Date) => {
  return moment(d).from(Date.now());
};

type GroupInfoLabels = {
  privacy: PrivacyLabels;
  visibility?: PrivacyLabels;
  createdAt: string;
};

export function useGroupInfoLabels(
  group: ApiTypes.v1.GroupResponse,
): GroupInfoLabels {
  const privacy = group.settings?.privacyLevel;
  const createdAt = getTimeFromCreateDate(group.createdAt!);
  const privacyLabels = PrivacyMap[privacy!];
  const visibility = VisibilityMap[privacy!];
  try {
    if (isGroupPrivate(group)) {
      const access = group.settings?.accessRestriction?.type!;
      privacyLabels.label = AccessRestrictionLabels[access];
    }
  } catch (e) {}
  return { createdAt, privacy: privacyLabels, visibility };
}

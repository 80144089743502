import React from 'react';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { PRIORITY } from 'wix-ui-tpa/Button';

import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';
import { PendingMember } from '@wix/social-groups-api/dist/src/model/Member/PendingMember';
import {
  MemberDTO,
  MemberResponse,
} from '@wix/social-groups-api/dist/src/model/Member/MemberDTO';
import { ItemsFilter } from '@wix/social-groups-api/dist/src/services/membersApi/MembersPublicApi';

import { classes, st } from './Members.st.css';
import { AdminActions } from './Member/AdminActions';
import { SortFilterSearch } from './SortFilterSearch/SortFilterSearch';
import { MembersList } from './MembersList';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../contexts/SiteMembers/withSiteMembers';
import { Container } from '../../../../common/components/Container/Container';
import { PendingMembers } from '../PendingMembers/PendingMembers';
import { QuestionsAnswersModal } from '../Modals/QuestionsAnswersModal/QuestionsAnswersModal';
import { ModalV2FooterProps } from '../../../../common/components/Modal/ModalV2';
import { Button } from '../../../../common/components/Button';
import { compose } from '../../../../common/utils/compose';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import { groupMembershipQuestionsOwnerViewAnswers } from '@wix/bi-logger-groups/v2';
import { Spinner } from '../../../../common/components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';
import { MEMBERS_TAB } from '../dataHooks';
import { ActionModals, DialogType } from './ActionModals';

export interface MembersComponentProps {
  className?: string;
  includeDeleted?: boolean;
  withMoreActions?: boolean; // TODO: why?
}

type InjectedProps = WithTranslation &
  Pick<WithGroupProps, 'group' | 'members'> & // TODO: why members are in WithGroupProps?
  Partial<WithSiteMembers> &
  TPAComponentsConfig &
  InjectedExperimentsProps;

interface MembersState {
  memberId: string | null;
  openDialogType: DialogType | null;
}

type Props = MembersComponentProps & InjectedProps & InjectedBiLoggerProps;

export class MembersComponent
  extends React.Component<Props, MembersState>
  implements AdminActions
{
  static defaultProps = {
    appSettings: {
      membersDisplay: {},
    },
    withMoreActions: false,
    questionsAnswers: {},
  };

  readonly state: MembersState = {
    memberId: null,
    openDialogType: null,
  };

  render() {
    const {
      group,
      members,
      t,
      openCurrentUserProfile,
      fetchMoreGroupMembers,
      hasMoreGroupMembers,
      mobile,
      includeDeleted,
      withMoreActions,
      siteMembersMap,
      ...rest
    } = this.props;
    const { openDialogType, memberId } = this.state;
    const siteMember = memberId && siteMembersMap![memberId];
    const member = new GroupMember(siteMember as any);

    const shouldRenderActionModals =
      withMoreActions && !!memberId && siteMember;
    return (
      <Container
        className={st(classes.root, {}, rest.className)}
        data-hook={MEMBERS_TAB}
      >
        <PendingMembers />
        {this.renderSortFilterSearch()}
        <InfiniteScroll
          pageStart={0}
          hasMore={hasMoreGroupMembers}
          initialLoad={false}
          loadMore={() => {
            fetchMoreGroupMembers && fetchMoreGroupMembers(group.groupId!);
          }}
          loader={
            <Spinner key="Spinner" offset="M" label={t('groups-web.loading')} />
          }
        >
          <MembersList
            members={members}
            withMoreActions={withMoreActions}
            onChangeAdmin={this.onChangeAdmin}
            onRemove={this.onRemove}
            onViewAnswers={this.onViewAnswers}
          />
        </InfiniteScroll>
        {shouldRenderActionModals ? (
          <ActionModals
            openDialogType={openDialogType!}
            groupId={group.groupId!}
            changeMemberRoleInGroup={this.props.changeMemberRoleInGroup!}
            removeMembersFromGroup={this.props.removeMembersFromGroup!}
            member={member as any}
            onRequestClose={this.closeDialog}
          />
        ) : null}
        {this.renderAnswers()}
      </Container>
    );
  }

  private renderSortFilterSearch() {
    if (
      this.props.experiments.enabled('specs.groups.MembersSortFilterSearch')
    ) {
      return <SortFilterSearch />;
    }
    return null;
  }

  onChangeAdmin = (memberId: string) => {
    this.openDialog(memberId, DialogType.CHANGE_ADMIN_ROLE);
  };

  onRemove = (memberId: string) => {
    this.openDialog(memberId, DialogType.REMOVE_MEMBER);
  };

  openDialog(memberId: string, actionDialogType: DialogType) {
    this.setState({ memberId, openDialogType: actionDialogType });
  }

  readonly onViewAnswers = (memberId: string) => {
    const {
      bi,
      group: { groupId },
      listMembershipQuestionAnswers,
    } = this.props;
    bi.report(
      groupMembershipQuestionsOwnerViewAnswers({
        groupId: groupId!,
        memberUuid: memberId,
        origin: 'member_questions_screen',
      }),
    );
    Promise.resolve(
      listMembershipQuestionAnswers!(groupId!, [memberId], ItemsFilter.BY_ID),
    ).catch((e) => console.log('Error in MembersComponent.onViewAnswers'));
    this.openDialog(memberId, DialogType.ANSWERS);
  };

  private renderAnswers() {
    const member = this.getGroupOrPendingMember();
    if (!member) {
      return null;
    }
    const { openDialogType } = this.state;
    const { mobile, questionsAnswers } = this.props;
    const id = member.getId();
    const qa = questionsAnswers![id];
    const footer = this.getAnswersFooter(member);
    return (
      <QuestionsAnswersModal
        userName={member.getName()}
        mobile={mobile}
        isOpen={openDialogType === DialogType.ANSWERS}
        onRequestClose={this.closeDialog}
        questionsAnswers={qa}
        {...footer}
      />
    );
  }

  private getAnswersFooter(
    member: MemberDTO<MemberResponse>,
  ): ModalV2FooterProps {
    const { t } = this.props;
    const memberId = member.getId();
    if (member.isPending()) {
      return {
        okButton: (
          <Button
            onClick={() => {
              // this.approvePendingMember('member_questions_screen_btn', memberId)
            }}
            priority={PRIORITY.primary}
          >
            {t('groups-web.members.pending.approve')}
          </Button>
        ),
        cancelButton: (
          <Button
            onClick={() => {
              // this.declinePendingMember('member_questions_screen_btn', memberId)
            }}
            priority={PRIORITY.secondary}
          >
            {t('groups-web.members.pending.decline')}
          </Button>
        ),
      };
    }
    return {
      okButton: (
        <Button onClick={this.closeDialog} priority={PRIORITY.primary}>
          {t('groups-web.close')}
        </Button>
      ),
    };
  }

  private getGroupMember() {
    const { memberId } = this.state;
    const { siteMembersMap } = this.props;
    if (!memberId || !siteMembersMap) {
      return null;
    }
    const member = siteMembersMap[memberId];
    return new GroupMember(member);
  }

  private getPendingMember() {
    const { memberId } = this.state;
    if (!memberId) {
      return null;
    }
    const member = this.getPendingMembers().find(
      (m) => m.siteMemberId === memberId,
    );

    return member ? new PendingMember(member) : null;
  }

  private getGroupOrPendingMember(): MemberDTO<MemberResponse> {
    return this.getPendingMember() || this.getGroupMember()!;
  }

  private getPendingMembers() {
    const { pendingMembers } = this.props;
    return (pendingMembers && pendingMembers.members) || [];
  }

  private readonly closeActionsMenu = () => {
    this.setState({ memberId: null });
  };

  private readonly closeDialog = () => {
    const { memberId, openDialogType } = this.state;
    if (!memberId && !openDialogType) {
      return;
    }

    this.setState({
      memberId: null,
      openDialogType: null,
    });
  };
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withSiteMembers,
  withTPAConfig,
  withBi,
  withExperiments,
);

export const Members = enhance(
  MembersComponent,
) as React.ComponentType<MembersComponentProps>;

export default Members;

import { useContext } from 'react';
import { UserActionsContext } from './UserActionsContext';

export function useUserActions() {
  const context = useContext(UserActionsContext);

  if (!context) {
    throw new Error(
      '`useMembership()` must be used within a `MembershipContextProvider`',
    );
  }

  return context;
}

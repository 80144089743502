import React from 'react';
// API
import {
  isAdmin,
  isJoined,
  isMember,
  isNotMember,
} from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { isGroupSecret } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';

import { ActionsBuilder, ActionsOnClickMap } from './ActionsBuilder';
import { GroupAction } from './GroupAction';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../contexts/GroupActions/WithGroupActions';
import { ThreeDots } from '../ThreeDots/ThreeDots';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { DeleteDialog } from '../Dialogs/DeleteDialog';
import { SideDrawer } from '../../../../common/components/SideDrawer/SideDrawer';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { compose } from '../../../../common/utils/compose';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { withUser } from '../../../../common/context/user/withUser';
import { IUserContext } from '../../../../common/context/user/IUserContext';

// LAZY
const NotificationSettings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationSettings" */ '../NotificationSettings/NotificationSettings'
    ),
);
const EditGroupSettings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GroupSettings" */ '../GroupSettings/GroupSettings'
    ),
);

interface GroupActionsState {
  isActionsMenuOpened: boolean;
  openDialog: GroupAction;
}

interface GroupActionsProps {
  iconClassName?: string;

  onShare(): void;
}

interface GroupActionsComponentProps
  extends WithGroupProps,
    WithTranslation,
    InjectedBiLoggerProps,
    WithGroupActionProps,
    IUserContext,
    GroupActionsProps {}

export class GroupActionsComponent extends React.Component<
  GroupActionsComponentProps,
  GroupActionsState
> {
  state: GroupActionsState = {
    openDialog: null as any,
    isActionsMenuOpened: false,
  };
  private readonly actionsOnClickMap: ActionsOnClickMap;

  constructor(props: Readonly<GroupActionsComponentProps>) {
    super(props);
    this.actionsOnClickMap = {
      [GroupAction.DELETE]: this.handleDeleteClick,
      [GroupAction.GROUP_SETTINGS]: this.openGroupSettingsDialog,
      [GroupAction.LEAVE]: this.handleLeaveClick,
      [GroupAction.SHARE]: props.onShare,
      [GroupAction.NOTIFICATION_SETTINGS]: this.openNotificationSettings,
    };
  }

  render() {
    const { group, iconClassName } = this.props;
    const groupWrapper = new GroupWrapper(group);
    const menuItems = this.getMenuItems();
    if (isNotMember(group as any) || menuItems.length === 0) {
      return null;
    }
    return (
      <>
        <ThreeDots
          onClose={this.closeActionsMenu}
          isOpen={this.state.isActionsMenuOpened}
          onOpen={this.openActionsMenu}
          items={menuItems}
          iconClassName={iconClassName}
        />
        {this.renderDeleteDialog(groupWrapper)}
        {this.renderDrawer()}
      </>
    );
  }

  private readonly handleDeleteClick = () => {
    this.sendDeleteGroupBIEvent('menu');
    this.openDeleteDialog();
  };

  private readonly handleLeaveClick = () => {
    const { group } = this.props;
    this.props.userActions.leaveGroup({ groupId: group.groupId! });
  };

  private readonly closeActionsMenu = () => {
    this.setState({ isActionsMenuOpened: false });
  };

  private readonly openActionsMenu = () => {
    this.setState({ isActionsMenuOpened: true });
  };

  private getNonMemberActions() {
    return [];
  }

  private getMemberActions() {
    const { t, group } = this.props;
    const builder = new ActionsBuilder(t, this.actionsOnClickMap);
    builder
      .addAction(GroupAction.NOTIFICATION_SETTINGS)
      .addAction(
        !isGroupSecret(group as any) ? GroupAction.SHARE : (null as any),
      )
      .addAction(GroupAction.LEAVE);
    return builder.build();
  }

  private getAdminActions() {
    const { t, group } = this.props;
    const builder = new ActionsBuilder(t, this.actionsOnClickMap);
    builder
      .addAction(GroupAction.NOTIFICATION_SETTINGS)
      .addAction(GroupAction.GROUP_SETTINGS)
      .addAction(!isGroupSecret(group) ? GroupAction.SHARE : (null as any));

    // TODO if (!isOwner(group, currentMember)) {
    //   builder.addAction(GroupAction.LEAVE);
    // }
    builder.addAction(GroupAction.DELETE);
    return builder.build();
  }

  private getMenuItems() {
    const { group } = this.props;
    // Admin
    if (isAdmin(group)) {
      return this.getAdminActions();
    }
    // Member
    if (isMember(group) && isJoined(group as any)) {
      return this.getMemberActions();
    }
    return this.getNonMemberActions();
  }

  private readonly openNotificationSettings = () => {
    const { bi, group } = this.props;
    bi.report(
      groupActionClick({
        action: 'notification_settings',
        group_id: group.groupId!,
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );
    this.setState({ openDialog: GroupAction.NOTIFICATION_SETTINGS });
  };

  private readonly openGroupSettingsDialog = () => {
    this.props.bi.report(
      groupActionClick({
        group_id: this.props.group.groupId!,
        action: 'group_settings',
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );

    this.setState({ openDialog: GroupAction.GROUP_SETTINGS });
  };

  private renderDeleteDialog(groupWrapper: GroupWrapper) {
    const { group } = this.props;
    return isAdmin(group) ? (
      <DeleteDialog
        isOpen={this.state.openDialog === GroupAction.DELETE}
        onRequestClose={this.closeDialog}
        groupTitle={groupWrapper.getTitle()}
        onDelete={this.deleteGroup}
      />
    ) : null;
  }

  private readonly closeDialog = () => {
    this.setState({ openDialog: null as any });
  };

  private readonly openDeleteDialog = () => {
    this.setState({ openDialog: GroupAction.DELETE });
  };

  private readonly deleteGroup = () => {
    try {
      this.sendDeleteGroupBIEvent('modal_dialog_button');
      this.props.deleteGroup(this.props.group.groupId!);
    } catch (e) {
      console.error('Delete Group failed');
    }
  };

  private renderDrawer() {
    const { openDialog } = this.state;
    const isOpen =
      openDialog === GroupAction.NOTIFICATION_SETTINGS ||
      openDialog === GroupAction.GROUP_SETTINGS;
    return (
      <SideDrawer isOpen={isOpen} onRequestClose={this.closeDialog}>
        {this.renderNotificationSettings()}
        {this.renderGroupSettings()}
      </SideDrawer>
    );
  }

  private renderGroupSettings() {
    const { group } = this.props;
    const { openDialog } = this.state;
    const render =
      isJoined(group as any) && openDialog === GroupAction.GROUP_SETTINGS;
    return render ? (
      <React.Suspense fallback={<Spinner />}>
        <EditGroupSettings onCancel={this.closeDialog} />
      </React.Suspense>
    ) : null;
  }

  private renderNotificationSettings() {
    const { group } = this.props;
    const { openDialog } = this.state;
    return isJoined(group as any) &&
      openDialog === GroupAction.NOTIFICATION_SETTINGS ? (
      <React.Suspense fallback={<Spinner />}>
        <NotificationSettings
          groupId={group.groupId!}
          onCancel={this.closeDialog}
        />
      </React.Suspense>
    ) : null;
  }

  private sendDeleteGroupBIEvent(biOrigin: string) {
    const { bi, group } = this.props;
    bi.report(
      groupActionClick({
        action: 'delete',
        group_id: group.groupId!,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
      }),
    );
  }
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  WithGroupActions,
  withBi,
  withUser,
);
export const GroupActions = enhance(
  GroupActionsComponent,
) as React.ComponentType<GroupActionsProps>;

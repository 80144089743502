import React from 'react';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { TextButton } from '../../TextButton/TextButton';
import { Header } from '../../Text/Header';
import { TPAModal } from '../../Modal';
import { compose } from '../../../utils/compose';
import { st, classes } from './MobileRepositionLayout.st.css';
import { Block } from '../../Block';

interface MobileRepositionLayoutProps {
  onCancel(): void;
  onSave(): void;
  loading: boolean;
}

type Props = MobileRepositionLayoutProps & WithTranslation;

class MobileRepositionLayoutComponent extends React.Component<Props> {
  render() {
    const { t, onCancel, onSave, loading } = this.props;
    return (
      <TPAModal
        isOpen={true}
        inFullScreen={true}
        onRequestClose={onCancel}
        withCloseButton={false}
        className={st(classes.root)}
      >
        <Block className={classes.header}>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.secondary}
            onClick={onCancel}
            forceBlackAndWhite
          >
            {t('groups-web.cancel')}
          </TextButton>
          <Header className={classes.title}>
            {t('groups-web.reposition.reposition-cta')}
          </Header>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.secondary}
            onClick={onSave}
            forceBlackAndWhite
            className={classes.done}
          >
            {t(
              loading
                ? 'groups-web.reposition.saving'
                : 'groups-web.reposition.save',
            )}
          </TextButton>
        </Block>
        <div>{this.props.children}</div>
      </TPAModal>
    );
  }
}

const enhance = compose(withTranslation());

export const MobileRepositionLayout = enhance(
  MobileRepositionLayoutComponent,
) as React.ComponentType<MobileRepositionLayoutProps>;

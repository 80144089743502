import React, { Component, ComponentType } from 'react';
import { EventsEmptyState } from './EventsEmptyState';

export const EventsErrorBoundary = <P extends object>(
  WrappedComponent: ComponentType<P>,
) =>
  class Events extends Component<P> {
    state = { hasError: false };

    static getDerivedStateFromError(error: any) {
      return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
      console.error(error, errorInfo);
    }

    render() {
      if (this.state.hasError) {
        return <EventsEmptyState />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };

export interface DeepLink {
  key: string;
  value: string;
}

export const DeepLinks = {
  createNewPost: {
    key: 'referralInfo',
    value: 'triggered_email_create_post',
  },
  inviteMembers: {
    key: 'referralInfo',
    value: 'triggered_email_invite_members',
  },
};

export const removeDeepLinkFromQuery = (
  query: string,
  deeplink: DeepLink,
): string => {
  const currentParams = new URLSearchParams(query);

  if (currentParams.has(deeplink.key)) {
    currentParams.delete(deeplink.key);
  }

  return currentParams.toString();
};

export const deepLinkPresentInQuery = (query: string, deepLink: DeepLink) => {
  const queryParams = new URLSearchParams(query);

  return queryParams.get(deepLink.key) === deepLink.value;
};

export const removeDeepLinkFromUrl = (
  location: Location,
  deeplink: DeepLink,
) => {
  const nextQuery = removeDeepLinkFromQuery(location.search, deeplink);
  const queryString = nextQuery ? `?${nextQuery}` : '';

  return `${location.pathname}${queryString}`;
};

import React, { useState } from 'react';

import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';

import { MobileLayout, DesktopLayout } from './EditorLayouts';

import { classes } from './CustomTab.st.css';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import { RichContentEditor } from '../RichContent/lazy';
import { PRESETS } from '../RichContent/types';

export interface EditorProps {
  contentState: any;

  handleCancelClick(): void;

  handleSubmitClick(contentState: RawDraftContentState<any>): Promise<any>;
}

export const Editor: React.FC<EditorProps> = (props) => {
  const { handleCancelClick } = props;
  const [contentState, setContentState] = useState(props.contentState);
  const [isLoading, setIsLoading] = useState(false);
  const { mobile } = React.useContext(TPAComponentsContext);

  const Layout = mobile ? MobileLayout : DesktopLayout;

  return (
    <Layout
      isLoading={isLoading}
      onCancel={handleCancelClick}
      onSubmit={handleSubmit}
    >
      <RichContentEditor
        autoFocus
        contentId="custom_tab"
        usage="CustomTab"
        isMobile={mobile}
        onChange={setContentState}
        content={contentState}
        preset={PRESETS.CUSTOM_TAB}
        className={classes.ricos}
        onBusyChange={handleEditorBusyChange}
      />
    </Layout>
  );

  function handleEditorBusyChange(isBusy: boolean) {
    setIsLoading(isBusy);
  }

  async function handleSubmit() {
    setIsLoading(true);
    await props.handleSubmitClick(contentState);
    setIsLoading(false);

    handleCancelClick();
  }
};

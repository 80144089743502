import { useContext } from 'react';
import { JoinGroupRequestsContext } from './JoinGroupRequestsContext';

export function useJoinGroupRequests() {
  const context = useContext(JoinGroupRequestsContext);

  if (!context) {
    throw new Error(
      '`useGroupV2()` must be used within a `JoinGroupRequestsContextProvider`',
    );
  }

  return context;
}

import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api';

import { getMembersLabelWithCount } from '../MembersLabel/MembersLabelWithCount';

import { st, classes } from './MembersCount.st.css';

export const MEMBERS_COUNT = 'members-count';

interface IMembersCountProps {
  group: ApiTypes.v1.GroupResponse;
  className?: string;
}

export const MembersCount: React.FC<IMembersCountProps> = (props) => {
  const { t } = useTranslation();

  const groupWrapper = new GroupWrapper(props.group);
  const membersCount = groupWrapper.getMembersCount();
  const membersLabel = groupWrapper.getMembersName();
  const label = getMembersLabelWithCount(membersLabel, membersCount, t);
  return (
    <Text
      data-hook={MEMBERS_COUNT}
      className={st(classes.root, {}, props.className)}
    >
      {label}
    </Text>
  );
};

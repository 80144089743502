import React from 'react';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { Text } from 'wix-ui-tpa/Text';

import { ApiTypes } from '@wix/social-groups-api';
import { GroupAppsMap } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupAppsMap';

import { classes } from './About.st.css';
import * as discussionStyles from '../Discussion.st.css';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../../contexts/GroupActions/WithGroupActions';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { Box } from '../../../../../common/components/Box/Box';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';

interface AboutComponentProps {
  group?: ApiTypes.v1.GroupResponse;
  apps: GroupAppsMap;
}

export interface AboutProps extends WithGroupActionProps, AboutComponentProps {}

type Props = AboutProps;
export const AboutComponent: React.FC<Props> = ({ group, changeTab, apps }) => {
  const descr = ContentConverter.parseText(group!.details!.description!);
  const bi = useBi();
  const { t } = useTranslation();
  const onReadMore = () => {
    bi.report(
      groupsTabClicked({
        group_id: group!.groupId!,
        origin: 'pinned_window',
        name: 'about',
      }),
    );

    changeTab('about');
  };

  const readMore =
    descr && descr.length > 60 ? (
      <>
        ...
        <TextButton
          onClick={onReadMore}
          priority={TEXT_BUTTON_PRIORITY.primary}
          className={classes.readMore}
        >
          {t('groups-web.more')}
        </TextButton>
      </>
    ) : null;
  return (
    <Box article data-hook="about-widget">
      <Text className={discussionStyles.classes.widgetHeader}>
        {getAboutTabName(apps) || t('groups-web.discussion.about.title')}
      </Text>
      <Text className={classes.description}>
        {(descr && descr.substr(0, 60)) ||
          t('groups-web.discussion.about.description')}
        {readMore}
      </Text>
    </Box>
  );
};

function getAboutTabName(apps: GroupAppsMap) {
  try {
    const { customName } = apps[ApiTypes.v1.GroupAppKey.ABOUT_APP] || null;
    return customName;
  } catch (e) {}
}

export const About = WithGroupActions(
  AboutComponent,
) as React.ComponentType<AboutComponentProps>;

import React from 'react';
import { Text, TextProps } from 'wix-ui-tpa/Text';
import { st, classes /* ... */ } from './Header.st.css';

interface HeaderProps extends TextProps {}

/**
 * Header
 *
 * Component for header text
 */
export const Header: React.FC<HeaderProps> = (props) => (
  <Text
    tagName="h2"
    {...props}
    className={st(classes.root, {}, props.className)}
  />
);

Header.displayName = 'Header';

import React, { useEffect } from 'react';

import { classes, st } from './PendingMembers.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ExpandableContainer } from '../../../../common/components/Container/ExpandableContainer';
import { PENDING_MEMBERS_EXPAND } from '../dataHooks';
import { usePendingMembers } from './usePendingMembers';
import { ShowMore } from './ShowMore';
import { Title } from './Title';
import { MembersList } from './MembersList';

interface PendingMembersComponentProps {
  // onShowAnswersClick?(member: M): void;
  // hasAnswers: boolean;
}

export const PendingMembers = function (props: PendingMembersComponentProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { loading, total, showMore, loadPendingMembers, canSeePendingMembers } =
    usePendingMembers();

  useEffect(() => {
    loadPendingMembers();
  }, []);

  if (!canSeePendingMembers || !total) {
    return null;
  }

  return (
    <ExpandableContainer
      initialExpanded={false}
      loading={loading}
      title={<Title t={t} total={total} />}
      className={st(classes.root, { mobile: isMobile } as any)}
      data-hook={PENDING_MEMBERS_EXPAND}
    >
      <div className={classes.membersList}>
        <MembersList />
        {showMore ? <ShowMore t={t} loadMore={loadPendingMembers} /> : null}
      </div>
    </ExpandableContainer>
  );
};

PendingMembers.displayName = 'PendingMembers';

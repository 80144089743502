import React from 'react';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import { canInviteMembers } from '@wix/social-groups-api';

import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../../contexts/GroupActions/WithGroupActions';
import { getMembersLabel } from '../../MembersLabel/MembersLabel';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { AddMembersModal } from '../../Modals/AddMemberModal/AddMembersModal';
import { compose } from '../../../../../common/utils/compose';
import {
  groupsAddMemberClicked,
  groupsTabClicked,
} from '@wix/bi-logger-groups/v2';

export interface MembersWidgetCTAButtonState {
  isModalOpen: boolean;
}

export class MembersWidgetCTAButtonComponent extends React.Component<
  WithTranslation &
    WithGroupProps &
    WithGroupActionProps &
    InjectedExperimentsProps &
    InjectedBiLoggerProps,
  MembersWidgetCTAButtonState
> {
  readonly state: MembersWidgetCTAButtonState = {
    isModalOpen: false,
  };

  handleModalClose = () => this.setState({ isModalOpen: false });

  handleModalOpen = () => this.setState({ isModalOpen: true });

  render() {
    const { t, changeTab, group, bi } = this.props;
    const { isModalOpen } = this.state;

    let action = () => {
      bi.report(
        groupsTabClicked({
          group_id: group.groupId!,
          origin: 'see_all_members_link',
          name: 'members',
        }),
      );
      changeTab('members');
    };

    const { key, value } = getMembersLabel(group, t);

    let label = t(`${key}.discussion.members.see-all`, {
      total: group.memberCount, // TODO: includes private profile members
      membersLabel: value,
    });

    if (canInviteMembers(group)) {
      label = t(`${key}.discussion.members.add`, { membersLabel: value });
      action = () => {
        bi.report(
          groupsAddMemberClicked({
            origin: 'add_members_link',
            groupId: group.groupId!,
            userEntry: BIUserEntry.SITE,
          }),
        );
        this.handleModalOpen();
      };
    }

    return (
      <>
        <TextButton onClick={action} priority={TEXT_BUTTON_PRIORITY.primary}>
          {label}
        </TextButton>

        <AddMembersModal
          group={group}
          isOpen={isModalOpen}
          handleClose={this.handleModalClose}
        />
      </>
    );
  }
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  WithGroupActions,
  withBi,
  withExperiments,
);

export const MembersWidgetCTAButton = enhance(
  MembersWidgetCTAButtonComponent,
) as React.ComponentType;

import React from 'react';
import { ModalProps } from '../../../../common/components/Modal/Modal';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from '../../../../common/components/Dialog/Dialog';

export interface RemoveMemberDialogComponentProps {
  memberFullName: string;
  onConfirmDeletion(): void;
}

type RemoveMemberDialogProps = ModalProps & RemoveMemberDialogComponentProps;

const RemoveMemberDialogComponent: React.FC<
  RemoveMemberDialogProps & WithTranslation
> = (props) => {
  const { t, onConfirmDeletion, memberFullName, ...rest } = props;
  const title = t('groups-web.group.actions.remove-member.modal.title');
  const text = t('groups-web.group.actions.remove-member.modal.caption', {
    memberFullName,
  });
  const cancel = t('groups-web.cancel');
  const confirm = t('groups-web.group.actions.remove-member.modal.confirm');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={confirm}
      cancelLabel={cancel}
      onOkClick={onConfirmDeletion}
      {...rest}
    />
  );
};

export const RemoveMemberDialog = withTranslation()(
  RemoveMemberDialogComponent,
) as React.ComponentType<RemoveMemberDialogProps>;
RemoveMemberDialog.displayName = 'RemoveMemberDialog';

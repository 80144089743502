import React from 'react';
import { ModalProps } from '../../../../../common/components/Modal/Modal';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import { Dialog } from '../../../../../common/components/Dialog/Dialog';
import { MembershipButton } from '../../Membership/MembershipButton';
import { compose } from '../../../../../common/utils/compose';
import { JOIN_GROUP_DIALOG } from '../../dataHooks';
import { withTheme, Theme } from '../../../../../common/context/theme';
import { st, classes } from './JoinDialog.st.css';

type Props = ModalProps & WithTranslation & WithGroupProps & Theme;

const JoinDialogComponent: React.FC<Props> = (props) => {
  const { t, group, forceBlackAndWhite, ...rest } = props;
  const joinTitle = t('groups-web.join.title');
  const joinText = t('groups-web.join.text', {
    group: group.details!.title,
    interpolation: { escapeValue: false },
  });

  return (
    <Dialog
      title={joinTitle}
      caption={joinText}
      {...rest}
      buttons={
        <MembershipButton
          className={st(classes.membershipButton, { bw: !!forceBlackAndWhite })}
          biOrigin="public_post_join_group_btn"
        />
      }
      dataHook={JOIN_GROUP_DIALOG}
    />
  );
};

const enhance = compose(withTranslation(), WithGroup, withTheme);

export const JoinDialog = enhance(
  JoinDialogComponent,
) as React.ComponentType<ModalProps>;

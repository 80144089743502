import React from 'react';

export const RichContentViewer = React.lazy(() =>
  import(/* webpackChunkName: "RCV" */ '../Viewer').then((module) => ({
    default: module.RichContentViewer, // this is to find usages of Editor
  })),
);

export const RichContentEditor = React.lazy(() =>
  import(/* webpackChunkName: "RCE" */ '../Editor').then((module) => ({
    default: module.Editor, // this is to find usages of Editor
  })),
);

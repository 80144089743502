import React from 'react';
import { useUser } from '../../context/user/useUser';
import { IUserActions, UserStatus } from '../../context/user/IUserContext';

export interface LoginParams {
  promptLogin: IUserActions['promptLogin'];
}

interface Props {
  login: React.FC<LoginParams>;
}

export const LoginView: React.FC<Props> = ({ login, children }) => {
  const { userActions, userStatus } = useUser();
  if (userStatus === UserStatus.UNKNOWN) {
    return login({ promptLogin: userActions.promptLogin });
  }
  return <>{children}</>;
};

LoginView.displayName = 'LoginView';

import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../types/IFeedItem';

export function getActivityBody(
  activity: IFeedItemActivity,
): RawDraftContentState<any> {
  switch (activity.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
    case FeedItemActivityType.DRAFT_JS:
      return ContentConverter.parseContentString(activity.data.content);
    default:
      return null as any;
  }
}

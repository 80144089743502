import { ApiErrorCode, ErrorOrigin, IErrorEvent } from './IErrorEvent';

export function groupNotFound(err: IErrorEvent) {
  if (!err) {
    return false;
  }
  const { code, origin } = err;
  return origin === ErrorOrigin.Group && code === 404;
}

export function mustRegisterToEvent(err: IErrorEvent) {
  if (!err) {
    return false;
  }
  const { apiError, origin } = err;
  return (
    origin === ErrorOrigin.JoinGroup &&
    apiError === ApiErrorCode.ACCESS_RESTRICTION_NOT_SATISFIED
  );
}

import {
  POST_ICONS_COLOR,
  POST_PLACEHOLDER_COLOR,
  POST_TEXT_COLOR,
} from './settingsConstants';
import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  /**
   * Layout styles
   */
  coverImageFullWidth: IStyleParam<StyleParamType.Boolean>;
  coverImageHeight: IStyleParam<StyleParamType.Number>;
  layoutSpacing: IStyleParam<StyleParamType.Number>;
  mobileCoverImageFullWidth: IStyleParam<StyleParamType.Boolean>;
  mobileCoverImageHeight: IStyleParam<StyleParamType.Number>;
  mobileLayoutSpacing: IStyleParam<StyleParamType.Number>;
  /**
   * Design styles
   */
  groupTitleColor: IStyleParam<StyleParamType.Color>;
  groupTitleFont: IStyleParam<StyleParamType.Font>;
  mobileGroupTitleFontSize: IStyleParam<StyleParamType.Number>;
  groupSubtitleColor: IStyleParam<StyleParamType.Color>;
  groupSubtitleFont: IStyleParam<StyleParamType.Font>;
  mobileGroupSubtitleFontSize: IStyleParam<StyleParamType.Number>;
  tabsColor: IStyleParam<StyleParamType.Color>;
  tabsFont: IStyleParam<StyleParamType.Font>;
  mobileTabsFontSize: IStyleParam<StyleParamType.Number>;
  commentsCommentContentColor: IStyleParam<StyleParamType.Color>;
  commentsCommentContentFont: IStyleParam<StyleParamType.Font>;
  commentsReplyColor: IStyleParam<StyleParamType.Color>;
  commentsReplyFont: IStyleParam<StyleParamType.Font>;
  commentsUserNameColor: IStyleParam<StyleParamType.Color>;
  commentsUserNameFont: IStyleParam<StyleParamType.Font>;
  commentsTimeStampColor: IStyleParam<StyleParamType.Color>;
  commentsTimeStampFont: IStyleParam<StyleParamType.Font>;
  commentsBoxBorderColor: IStyleParam<StyleParamType.Color>;
  commentsBoxBorderWidth: IStyleParam<StyleParamType.Number>;
  commentsPlaceholderColor: IStyleParam<StyleParamType.Color>;
  commentsPlaceholderFont: IStyleParam<StyleParamType.Font>;
  commentsMoreActionIcon: IStyleParam<StyleParamType.Color>;
  commentsLike: IStyleParam<StyleParamType.Color>;
  commentsShowMoreCommentsLink: IStyleParam<StyleParamType.Color>;
  commentsShowMoreRepliesLink: IStyleParam<StyleParamType.Color>;
  commentsRceIcons: IStyleParam<StyleParamType.Color>;
  postsBackgroundColor: IStyleParam<StyleParamType.Color>;
  postsBorderColor: IStyleParam<StyleParamType.Color>;
  postsBorderWidth: IStyleParam<StyleParamType.Number>;
  postsTextColor: IStyleParam<StyleParamType.Color>;
  postsTextFont: IStyleParam<StyleParamType.Font>;
  mobilePostsTextFontSize: IStyleParam<StyleParamType.Number>;
  createNewPostColor: IStyleParam<StyleParamType.Color>;
  createNewPostFont: IStyleParam<StyleParamType.Font>;
  memberNameColor: IStyleParam<StyleParamType.Color>;
  memberNameFont: IStyleParam<StyleParamType.Font>;
  postingDateColor: IStyleParam<StyleParamType.Color>;
  postingDateFont: IStyleParam<StyleParamType.Font>;
  commentsApplicationBackground: IStyleParam<StyleParamType.Color>;
  commentsReactionsCounterColor: IStyleParam<StyleParamType.Color>;
  commentsReactionsCounterFont: IStyleParam<StyleParamType.Font>;
  secondaryButtonTextColor: IStyleParam<StyleParamType.Color>;
  secondaryButtonBackgroundColor: IStyleParam<StyleParamType.Color>;
  secondaryButtonBorderColor: IStyleParam<StyleParamType.Color>;
  buttonTextColor: IStyleParam<StyleParamType.Color>;
  buttonTextFont: IStyleParam<StyleParamType.Font>;
  buttonColor: IStyleParam<StyleParamType.Color>;
  buttonBorderColor: IStyleParam<StyleParamType.Color>;
  buttonBorderWidth: IStyleParam<StyleParamType.Number>;
  buttonBorderRadius: IStyleParam<StyleParamType.Number>;
  textButtonColor: IStyleParam<StyleParamType.Color>;
  textButtonFont: IStyleParam<StyleParamType.Font>;
  overlayColor: IStyleParam<StyleParamType.Color>;
  iconOverlayColor: IStyleParam<StyleParamType.Color>;
  groupHeaderIconsColor: IStyleParam<StyleParamType.Color>;
  postIconsColor: IStyleParam<StyleParamType.Color>;
  appBackgroundColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutTitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutTitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsAboutSubtitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsAboutSubtitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsMembersTitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsMembersTitleFont: IStyleParam<StyleParamType.Font>;
  infoPanelsMembersSubtitleColor: IStyleParam<StyleParamType.Color>;
  infoPanelsMembersSubtitleFont: IStyleParam<StyleParamType.Font>;
  selectedTabWidth: IStyleParam<StyleParamType.Number>;
  selectedTabColor: IStyleParam<StyleParamType.Color>;
  dividerTabWidth: IStyleParam<StyleParamType.Number>;
  dividerTabColor: IStyleParam<StyleParamType.Color>;
};
export const stylesParams: IStylesParams = {
  /**
   * Layout styles
   */
  coverImageFullWidth: {
    getDefaultValue: () => false,
    key: 'coverImageFullWidth',
    type: StyleParamType.Boolean,
  },
  coverImageHeight: {
    getDefaultValue: () => 240,
    key: 'coverImageHeight',
    type: StyleParamType.Number,
  },
  layoutSpacing: {
    getDefaultValue: () => 20,
    key: 'layoutSpacing',
    type: StyleParamType.Number,
  },
  mobileCoverImageFullWidth: {
    getDefaultValue: () => false,
    key: 'mobileCoverImageFullWidth',
    type: StyleParamType.Boolean,
  },
  mobileCoverImageHeight: {
    getDefaultValue: () => 240,
    key: 'mobileCoverImageHeight',
    type: StyleParamType.Number,
  },
  mobileLayoutSpacing: {
    getDefaultValue: () => 20,
    key: 'mobileLayoutSpacing',
    type: StyleParamType.Number,
  },

  /**
   * Design styles
   */
  groupTitleColor: {
    key: 'groupTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  groupTitleFont: {
    key: 'groupTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S', {
      size: 20,
    }),
  },
  mobileGroupTitleFontSize: {
    key: 'mobileGroupTitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  groupSubtitleColor: {
    key: 'groupSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupSubtitleFont: {
    key: 'groupSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  mobileGroupSubtitleFontSize: {
    key: 'mobileGroupSubtitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },

  tabsColor: {
    key: 'tabsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tabsFont: {
    key: 'tabsFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  mobileTabsFontSize: {
    key: 'mobileTabsFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },

  commentsCommentContentColor: {
    key: 'commentsCommentContent',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsCommentContentFont: {
    key: 'commentsCommentContent',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },

  commentsReplyColor: {
    key: 'commentsReply',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsReplyFont: {
    key: 'commentsReply',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  commentsUserNameColor: {
    key: 'commentsUserName',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsUserNameFont: {
    key: 'commentsUserName',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  commentsTimeStampColor: {
    key: 'commentsTimeStamp',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsTimeStampFont: {
    key: 'commentsTimeStamp',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsBoxBorderColor: {
    key: 'commentsBoxBorder',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  commentsBoxBorderWidth: {
    key: 'commentsBoxBorder',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  commentsPlaceholderColor: {
    key: 'commentsPlaceholder',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_PLACEHOLDER_COLOR),
  },
  commentsPlaceholderFont: {
    key: 'commentsPlaceholder',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsMoreActionIcon: {
    key: 'commentsMoreActionIcon',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentsLike: {
    key: 'commentsLike',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentsShowMoreCommentsLink: {
    key: 'commentsShowMoreCommentsLink',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsShowMoreRepliesLink: {
    key: 'commentsShowMoreRepliesLink',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsRceIcons: {
    key: 'commentsRceIcons',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_ICONS_COLOR),
  },
  postsBackgroundColor: {
    key: 'postsBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  postsBorderColor: {
    key: 'postsBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  postsBorderWidth: {
    key: 'postsBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  postsTextColor: {
    key: 'postsTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  postsTextFont: {
    key: 'postsTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  mobilePostsTextFontSize: {
    key: 'mobilePostsTextFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  createNewPostColor: {
    key: 'createNewPostColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_PLACEHOLDER_COLOR),
  },
  createNewPostFont: {
    key: 'createNewPostFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  memberNameColor: {
    key: 'memberNameColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  memberNameFont: {
    key: 'memberNameFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  postingDateColor: {
    key: 'postingDateColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  postingDateFont: {
    key: 'postingDateFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsApplicationBackground: {
    key: 'commentsApplicationBackground',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  commentsReactionsCounterColor: {
    key: 'commentsReactionsCounterColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsReactionsCounterFont: {
    key: 'commentsReactionsCounterFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  secondaryButtonTextColor: {
    key: 'secondaryButtonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const primaryBackgroundColor = props.getStyleParamValue(
        stylesParams.buttonColor,
      );

      return primaryBackgroundColor
        ? primaryBackgroundColor
        : wixColorParam('color-8')(props);
    },
  },
  secondaryButtonBackgroundColor: {
    key: 'secondaryButtonBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      return { value: 'rgb(255, 255, 255)', name: null, opacity: 0 };
    },
  },
  secondaryButtonBorderColor: {
    key: 'secondaryButtonBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const primaryBackgroundColorValue = props.getStyleParamValue(
        stylesParams.buttonColor,
      );

      return primaryBackgroundColorValue
        ? primaryBackgroundColorValue
        : stylesParams.buttonBorderColor.getDefaultValue?.(props);
    },
  },
  buttonTextColor: {
    key: 'buttonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonTextFont: {
    key: 'buttonTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-L', {
      size: 16,
    }),
  },
  buttonColor: {
    key: 'buttonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColor: {
    key: 'buttonBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const backgroundColorValue = props.getStyleParamValue(
        stylesParams.buttonColor,
      );

      return backgroundColorValue
        ? backgroundColorValue
        : stylesParams.buttonColor.getDefaultValue?.(props);
    },
  },
  buttonBorderWidth: {
    key: 'buttonBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderRadius: {
    key: 'buttonBorderRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  textButtonColor: {
    key: 'textButtonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  textButtonFont: {
    key: 'textButtonFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  overlayColor: {
    key: 'overlayColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  iconOverlayColor: {
    key: 'iconOverlayColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  groupHeaderIconsColor: {
    key: 'groupHeaderIconsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  postIconsColor: {
    key: 'postIconsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_ICONS_COLOR),
  },

  appBackgroundColor: {
    key: 'appBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  infoPanelsAboutTitleColor: {
    key: 'infoPanelsAboutTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsAboutTitleFont: {
    key: 'infoPanelsAboutTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {
        bold: true,
        italic: false,
        underline: false,
      },
    }),
  },
  infoPanelsAboutSubtitleColor: {
    key: 'infoPanelsAboutSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsAboutSubtitleFont: {
    key: 'infoPanelsAboutSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  infoPanelsMembersTitleColor: {
    key: 'infoPanelsMembersTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsMembersTitleFont: {
    key: 'infoPanelsMembersTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {
        bold: true,
        italic: false,
        underline: false,
      },
    }),
  },
  infoPanelsMembersSubtitleColor: {
    key: 'infoPanelsMembersSubtitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoPanelsMembersSubtitleFont: {
    key: 'infoPanelsMembersSubtitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  selectedTabWidth: {
    key: 'selectedTabWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  },
  selectedTabColor: {
    key: 'selectedTabColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  dividerTabWidth: {
    key: 'dividerTabWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerTabColor: {
    key: 'dividerTabColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
};

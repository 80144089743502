import { Member } from '../Member/Member';
import { IGroupMember } from './IGroupMember';
import { Member as M } from '@wix/ambassador-members-v1-member/types';
import { IGroupV2 } from '../../../../components/Group/contexts/Group/IGorupV2';
import {
  isAdmin,
  joined,
} from '@wix/social-groups-api/dist/src/model/v2/Membership';
import { isPublic } from '@wix/social-groups-api/dist/src/model/v2/Group/privacyLevel';

export class GroupMember extends Member implements IGroupMember {
  constructor(member: M, private groupV2: IGroupV2) {
    super(member);
  }

  canShare(): boolean {
    return this.isAdmin() || isPublic(this.groupV2.groupV2!);
  }

  isAdmin(): boolean {
    return isAdmin(this.groupV2.membership!);
  }

  isAnonymous(): boolean {
    return false;
  }

  isJoined(): boolean {
    return joined(this.groupV2.membership!);
  }

  isMember(): boolean {
    return this.isJoined();
  }

  relatedToGroup(): boolean {
    throw new Error('Method not implemented.');
  }
}

import React from 'react';
import { MembersQueryInitialResponse } from '@wix/social-groups-api/dist/src/model/Member/MembersQueryResponse';
import { SiteMembers } from '../../controllers/members/SiteMembers';
import { MembersActionsType } from '../../controllers/members/MembersActions';
import { Badges } from '../../controllers/members/Badges';

export interface SiteMembersWithActions extends SiteMembers, Badges {
  membersActions: MembersActionsType;
  hasMoreGroupMembers: boolean;
}

export const defaultMembersContext: SiteMembersWithActions = {
  membersUpdate: undefined as any,
  membersActions: undefined as any,
  membersQueryResponse: MembersQueryInitialResponse,
  newMembers: undefined as any,
  siteMembers: [],
  siteMembersMap: {},
  nonGroupMembersCount: undefined as any,
  questionsAnswers: {},
  membersRequest: {},
  badges: {},
  membersBadgeIds: {},
  hasMoreGroupMembers: false,
};
export const SiteMembersContext = React.createContext<SiteMembersWithActions>(
  defaultMembersContext,
);

SiteMembersContext.displayName = 'SiteMembersContext';

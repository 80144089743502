import React from 'react';
import classname from 'classnames';
import { Button } from 'wix-ui-tpa/Button';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './EditLayout.st.css';
import { classes as commonClasses } from '../SuperHeroImage.st.css';
import { Reposition as RepositionIcon } from '../../Icons/Reposition';
import { PhotoCameraFill as PhotoCameraFillIcon } from '../../Icons/PhotoCameraFill';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../../components/Group/contexts/TPAComponent/withTpaComponentsConfig';
import { compose } from '../../../utils/compose';

interface EditLayoutProps {
  withReposition: boolean;
  onLogoUpload(image: File): void;
  onRepositionClick(): void;
}

type Props = EditLayoutProps & WithTranslation & WithTpaComponentsConfigProps;

interface EditLayoutState {}

class EditLayoutComponent extends React.Component<Props, EditLayoutState> {
  private readonly $input = React.createRef<HTMLInputElement>();

  render() {
    const { onRepositionClick, withReposition, t, mobile } = this.props;
    return (
      <div
        className={st(classes.root, { mobile: !!mobile }, commonClasses.layout)}
      >
        <div className={classes.buttons}>
          <Button
            className={classname(
              commonClasses.greyButton,
              classes.changePhotoButton,
            )}
            onClick={this.handleUploadButtonClick}
            prefixIcon={<PhotoCameraFillIcon />}
            upgrade
          >
            <span className={classes.changePhotoButtonLabel}>
              {t('groups-web.reposition.change-photo')}
            </span>
          </Button>
          {withReposition ? (
            <Button
              className={classname(
                commonClasses.greyButton,
                classes.repositionButton,
              )}
              onClick={onRepositionClick}
              prefixIcon={<RepositionIcon />}
              upgrade
            >
              {t('groups-web.reposition.reposition-cta')}
            </Button>
          ) : null}
        </div>
        <input
          hidden
          data-hook="image-input"
          type="file"
          multiple={false}
          ref={this.$input}
          onChange={this.handleFileInputChange}
        />
      </div>
    );
  }

  private handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const [userImage] = Array.from(event.target.files!);
    try {
      this.props.onLogoUpload(userImage);
    } catch (e) {
      // TODO: handle error (?)
      console.log(e);
    } finally {
      this.$input.current!.value = null as any;
    }
  };

  private handleUploadButtonClick = () => {
    this.$input.current!.click();
  };
}

const enhance = compose(withTranslation(), withTpaComponentsConfig);

export const EditLayout = enhance(
  EditLayoutComponent,
) as React.ComponentType<EditLayoutProps>;

import React from 'react';
import { GroupAppsMap } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupAppsMap';
import { GroupContextValue } from './GroupContextValue';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';

export const defaultGroupContextValue = {
  apps: {} as GroupAppsMap,
  updateProgress: UpdateProgress.STALE,
  group: null as any,
  members: [],
  rules: [],
  currentMember: null as any,
  uploadedRegistry: [],
  externalVideosMetadataRegistry: [],
  questions: [],
  media: {
    items: null as any,
    fetch() {
      return Promise.resolve();
    },
  },
  feed: {
    feedTopics: [],
    feedFilters: {},
    feedLoading: false,
    contextToken: null as any,
    cursor: null as any,
    prevCursor: null as any,
    fetchMore: null as any,
    isFeedItemCreating: false,
    feedItems: [],
    mediaItems: [],
    deleteFeedItem: null as any,
    applyFeedFilters: null as any,
    createFeedItem: null as any,
    createPostTopic: null as any,
    updateFeedItem: null as any,
    pinFeedItem: null as any,
    unpinFeedItem: null as any,
    followFeedItem: null as any,
    unfollowFeedItem: null as any,
    reactFeedItem: null as any,
    unreactFeedItem: null as any,
  },
};
export const GroupContext = React.createContext<GroupContextValue>(
  defaultGroupContextValue,
);
GroupContext.displayName = 'GroupContext';

export const GroupContextProvider = GroupContext.Provider;
export const GroupContextConsumer = GroupContext.Consumer;

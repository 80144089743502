import { GroupAction } from './GroupAction';
import { TFunction } from '@wix/yoshi-flow-editor';
import { ThreeDotsActions } from '../ThreeDots/ThreeDots';

export type ActionsOnClickMap = { [type in GroupAction]: () => void };

export class ActionsBuilder {
  private readonly actions: ThreeDotsActions[];
  private readonly t: TFunction;
  private readonly actionsOnClickMap: ActionsOnClickMap;
  constructor(t: TFunction, actionsOnClickMap: any) {
    this.actionsOnClickMap = actionsOnClickMap;
    this.actions = [];
    this.t = t;
  }

  addAction(type: GroupAction) {
    const { t } = this;
    if (type) {
      this.actions.push({
        onClick: this.actionsOnClickMap[type],
        content: t(type),
      });
    }
    return this;
  }

  build() {
    return this.actions;
  }
}

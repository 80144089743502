import { useContext } from 'react';
import { GroupV2Context } from './GroupV2Context';

export function useGroupV2() {
  const context = useContext(GroupV2Context);

  if (!context) {
    throw new Error(
      '`useGroupV2()` must be used within a `GroupV2ContextProvider`',
    );
  }

  return context;
}

import React from 'react';
import moment from 'moment';
import { AvatarSize } from 'wix-ui-tpa/Avatar';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { UserCard } from '../../../../common/components/Cards/UserCard';
import { PendingActions } from './PendingActions';
import { classes } from './PendingMembers.st.css';
import { StyledDivider } from '../../../../common/components/Divider/Divider';
import { usePendingMembers } from './usePendingMembers';
import { GroupPendingMember } from '../../../../common/api/model/GroupMember/GroupPendingMember';

interface Props {}

export const MembersList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    pendingMembers,
    approvePendingMember,
    declinePendingMember,
    isMemberUpdating,
  } = usePendingMembers();
  if (!pendingMembers) {
    return null;
  }
  return (
    <>
      {pendingMembers!.map((member: GroupPendingMember, i: number) => {
        const divider = i < pendingMembers!.length - 1;
        const id = member.getId();
        const date = `${moment(member.getRequestDate()).fromNow()}`;
        const updating = isMemberUpdating(id);
        return (
          <>
            <UserCard
              key={id}
              name={member.getName(t('groups-web.member.anonymous'))}
              pic={member.getImageUrl()}
              subtitle={date}
              userAction={
                !isMobile ? (
                  <PendingActions
                    memberId={id}
                    t={t}
                    approvePendingMember={approvePendingMember}
                    declinePendingMember={declinePendingMember}
                    disabled={updating}
                  />
                ) : null
              }
              avatarSize={AvatarSize.large}
              className={classes.userCard}
              mobile={isMobile}
            >
              {/* TODO MQ
              {member.hasAnswers() ? (
                    <TextButton
                      priority={TEXT_BUTTON_PRIORITY.primary}
                      className={classes.showAnswers}
                      onClick={() => onShowAnswersClick!(m)}
                    >
                      {t('groups-web.membership-questions.show-answers', {
                        count: member.getAnswersCount(),
                      })}
                    </TextButton>
                  ) : null}
              */}
            </UserCard>

            {isMobile ? (
              <div className={classes.actions}>
                <PendingActions
                  memberId={id}
                  t={t}
                  approvePendingMember={approvePendingMember}
                  declinePendingMember={declinePendingMember}
                  disabled={updating}
                />
              </div>
            ) : null}
            {divider && <StyledDivider className={classes.divider} />}
          </>
        );
      })}
    </>
  );
};

MembersList.displayName = 'MembersList';

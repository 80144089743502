import { PluginTypes } from '../types';

import { useMentions } from './useMentions';
import { usePolls } from './usePolls';

export function useHelpers() {
  const mentions = useMentions();
  const polls = usePolls();

  return {
    [PluginTypes.Mention]: mentions,
    [PluginTypes.Poll]: polls,
  };
}

import {
  deepLinkPresentInQuery,
  DeepLinks,
  removeDeepLinkFromUrl,
} from '../../../../../common/deeplinks/deeplinks';

export * from './NewPost';

export const isCreateNewPostDeepLinkPresent = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    return deepLinkPresentInQuery(
      window.location.search,
      DeepLinks.createNewPost,
    );
  } catch (e) {
    console.error(
      '[Discussion] Failed to retrieve create new post deeplink from url',
    );
  }
  return false;
};

export const removeCreateNewPostDeepLink = () => {
  try {
    if (isCreateNewPostDeepLinkPresent()) {
      const nextUrl = removeDeepLinkFromUrl(
        window.location,
        DeepLinks.createNewPost,
      );

      window.history.replaceState(null, '', nextUrl);
    }
  } catch (e) {
    console.log('[Discussion.removeOpenNewPostModalFromLocation] Failed');
  }
};

import React from 'react';

import { IconButton, Skins } from 'wix-ui-tpa/IconButton';

import { classes, st } from './Header.st.css';
import { isGroupSecret } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../contexts/GroupActions/WithGroupActions';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import {
  withAppData,
  WithAppDataProps,
} from '../../contexts/AppData/withAppData';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { CoverImageLayout } from '../../Settings/settingsConstants';
import { MembershipButton } from '../Membership/MembershipButton';
import { InviteMembers } from '../InviteMembers/InviteMembers';
import { ArrowBackIcon } from '../icons/ArrowBackIcon';
import { Details } from '../Details/Details';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { ShareModal } from '../../../../common/components/ShareModal/ShareModal';
import { getShareUrl } from '../Share/getShareUrl';
import { ShareButton } from '../Share/ShareButton';
import { GroupActions } from '../GroupActions/GroupActions';
import { compose } from '../../../../common/utils/compose';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../Settings/settingsParams';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { SuperHeroImageChanges } from '../../../../common/components/SuperHeroImage/SuperHeroImage';
import { AppToastTypes } from '@wix/social-groups-api';
import { GroupDTO } from '@wix/social-groups-api/dist/src/model/Group/GroupDTO';
import {
  GroupUpdate,
  withGroupUpdate,
} from '../../contexts/GroupActions/GroupUpdate';
import { withAppToasts } from '../../../../common/components/AppToats';
import { WithAppToastsProps } from '../../../../common/types/withAppToastsProps';
import { Cover } from './Cover';
import { GROUP_HEADER } from '../dataHooks';

interface HeaderState {
  isShareModalOpen: boolean;
}

type HeaderProps = { className?: string };
type Props = WithGroupProps &
  WithSettingsProps &
  GroupUpdate &
  InjectedExperimentsProps &
  WithGroupActionProps &
  WithAppToastsProps &
  WithTpaComponentsConfigProps &
  InjectedBiLoggerProps &
  WithAppDataProps &
  WithTranslation &
  HeaderProps;

export class HeaderComponent extends React.Component<Props, HeaderState> {
  state: HeaderState = { isShareModalOpen: false };
  renderHeaderButtons() {
    const { mobile } = this.props;

    const actions = [
      () => (
        <MembershipButton
          className={classes.membershipButton}
          biOrigin="group_feed_top_banner_btn"
          key="MembershipButton"
        />
      ),
      () => <InviteMembers />,
      () => !mobile && this.renderShareButton(),
      () => !mobile && this.renderGroupActions(classes.threeDotsIcon),
    ];

    return actions.map((action) => action());
  }
  render() {
    const { goToGroupList, mobile } = this.props;
    const { coverImageLayout, withImage } = this.getLayout();
    return (
      <div
        className={st(classes.root, {
          withImage,
          mobile,
          coverImageLayout,
        } as any)}
        data-hook={GROUP_HEADER}
      >
        <div className={classes.image}>
          {withImage ? (
            <Cover
              group={this.props.group}
              onChange={this.handleCoverChange}
              onSaved={this.showGroupLogoChangedToast}
            />
          ) : null}
        </div>
        {mobile ? (
          <div className={classes.mobileActionsHolder}>
            {withImage ? (
              <div className={classes.buttonsOverlayOnImage} />
            ) : null}
            <div className={classes.mobileActions}>
              <div className={classes.mobileActionsContainer}>
                <div className={classes.leftButtonsBlock}>
                  <IconButton
                    data-hook="arrow-back-button"
                    skin={Skins.Full}
                    icon={<ArrowBackIcon width="24px" height="24px" />}
                    className={classes.arrowBackIcon}
                    onClick={() => {
                      // need to omit event passing
                      goToGroupList();
                    }}
                  />
                </div>
                <div className={classes.rightButtonsBlock}>
                  {this.renderShareButton()}
                  {this.renderGroupActions(classes.groupActionsButtonMobile)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.groupInfo}>
          <Details />
        </div>
        <div className={classes.groupActions}>{this.renderHeaderButtons()}</div>
        {this.renderShareModal()}
      </div>
    );
  }

  private getLayout() {
    const { settings, mobile } = this.props;
    const settingsKeyFor = getSettingsKeyFor('coverImageLayout', mobile!);
    const coverImageLayout = settings.get(settingsParams[settingsKeyFor]);
    const withImage = coverImageLayout !== CoverImageLayout.blank;
    return { coverImageLayout, withImage };
  }

  private renderShareModal() {
    const { t } = this.props;
    return (
      <ShareModal
        isOpen={this.state.isShareModalOpen}
        onRequestClose={this.closeShareModal}
        title={t('groups-web.group.actions.share.group')}
        shareUrl={getShareUrl()}
      />
    );
  }

  private renderShareButton() {
    const { group, mobile } = this.props;

    if (isGroupSecret(group)) {
      return null;
    }

    return (
      <ShareButton
        onClick={() => this.openShareModal('arrow')}
        className={mobile ? classes.shareButtonMobile : classes.shareButton}
      />
    );
  }

  private renderGroupActions(iconClassName?: string) {
    return (
      <GroupActions
        onShare={() => this.openShareModal('menu')}
        iconClassName={iconClassName}
      />
    );
  }

  private readonly closeShareModal = () =>
    this.setState({ isShareModalOpen: false });

  private readonly openShareModal = (biOrigin: string) => {
    const { bi, group } = this.props;
    this.setState({ isShareModalOpen: true });
    bi.report(
      groupActionClick({
        action: 'share',
        group_id: group.groupId!,
        origin: biOrigin,
      }),
    );
  };

  private readonly handleCoverChange = ({
    imageResponse,
    focalPointY,
  }: SuperHeroImageChanges) => {
    const { updateGroup } = this.props;
    const groupWrapper = new GroupDTO(this.props.group);
    let group = groupWrapper.setLogoPosition({
      x: 0,
      y: focalPointY,
    });
    if (imageResponse) {
      group = groupWrapper.setLogoUrl(imageResponse);
    }
    updateGroup(groupWrapper.getUpdatePaths(), group.details!);
  };

  private readonly showGroupLogoChangedToast = () => {
    this.props.toasts.add({
      type: AppToastTypes.GROUP_LOGO_UPDATED,
    });
  };
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withExperiments,
  withGroupUpdate,
  WithGroupActions,
  withTpaComponentsConfig,
  withSettings,
  withBi,
  withAppData,
  withAppToasts,
);

export const Header = enhance(
  HeaderComponent,
) as React.ComponentType<HeaderProps>;

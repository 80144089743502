import { useContext } from 'react';
import { MemberFollowContext } from './MemberFollow';

export function useMemberFollow() {
  const context = useContext(MemberFollowContext);

  if (!context) {
    throw new Error(
      '`useMemberFollow()` must be used within a `MemberFollowContext`',
    );
  }

  return context;
}

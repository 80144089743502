import React from 'react';

import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';

import { getBreadcrumbMap } from './helpers';
import { classes, st } from './Navigation.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { ArrowBackIcon } from '../../icons/ArrowBackIcon';
import { Breadcrumbs } from '../../../../../common/components/Breadcrumbs/Breadcrumbs';
import { EFilterKeys } from '../../../types/EFilterKeys';

export const NavigationComponent: React.FC<WithTranslation> = ({ t }) => {
  const { feed } = React.useContext(GroupContext);
  const { feedFilters: filters, feedTopics } = feed;
  const labels = getBreadcrumbMap(t);

  if (!filters || !hasFilters(filters)) {
    return null;
  }

  return (
    <div className={st(classes.root)}>
      {filters.feedItemId ? (
        <TextButton
          onClick={clearFilters}
          prefixIcon={<ArrowBackIcon width="24px" height="24px" />}
          priority={TEXT_BUTTON_PRIORITY.secondary}
          className={classes.back}
        >
          {t('groups-web.discussion.post-not-found.back')}
        </TextButton>
      ) : (
        <Breadcrumbs
          items={[
            {
              label: t('groups-web.discussion.title'),
              action: clearFilters,
            },
            {
              label: labels[EFilterKeys.TOPICS](feedTopics, filters),
            },
          ]}
        />
      )}
    </div>
  );

  function clearFilters() {
    feed.applyFeedFilters();
  }
};

function hasFilters(filters: any) {
  return Object.values(filters).some((value) => !!value);
}

export const Navigation = withTranslation()(NavigationComponent) as any;

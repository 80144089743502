import React from 'react';
import { AvatarSize } from 'wix-ui-tpa/Avatar';

import { classes } from './NewPostModal.st.css';
import { DATA_HOOKS } from '../../About/dataHooks';
import { UserCard } from '../../../../../common/components/Cards/UserCard';
import { useUser } from '../../../../../common/context/user/useUser';
import { memberWrapper } from '../../../../../common/api/model/Member';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface Props {
  forceBlackAndWhite: boolean;
}

export const Author: React.FC<Props> = ({ forceBlackAndWhite }) => {
  const { t } = useTranslation();
  const { myMember } = useUser();
  const { nickname, photo } = memberWrapper(myMember!).profile!;
  return (
    <div className={classes.profileInfo}>
      <UserCard
        forceBlackAndWhite={forceBlackAndWhite}
        name={nickname || t('groups-web.member.anonymous')}
        pic={photo?.url}
        avatarSize={AvatarSize.large}
        dataHook={DATA_HOOKS.createdBy}
      />
    </div>
  );
};

Author.displayName = 'Author';

import React from 'react';
import { About } from './About';
import { DATA_HOOKS } from './dataHooks';
// STYLES
import { st, classes } from './AboutPage.st.css';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import { GroupActivity } from './GroupActivity/GroupActivity';
import { GroupRules } from './GroupRules/GroupRules';
import { GroupInfo } from './GroupInfo/GroupInfo';
import { compose } from '../../../../common/utils/compose';

export const AboutPageComponent: React.FC<
  WithGroupProps & WithTpaComponentsConfigProps
> = (props) => {
  const { group, updateProgress, mobile, rules = [] } = props;

  /**
   * Changed to flex layout, 🤔
   * because when grid is used => RCE can took place of column-gap too
   */
  const renderDesktop = () => (
    <div
      className={st(classes.root, { mobile } as any)}
      data-hook={DATA_HOOKS.root}
    >
      <div className={classes.bigColumn}>
        <GroupActivity group={group} />
        <About group={group} updateProgress={updateProgress} />
        <GroupRules rules={rules} />
      </div>
      <div className={classes.gap}></div>
      <div className={classes.smallColumn}>
        <GroupInfo group={group} />
      </div>
    </div>
  );

  const renderMobile = () => (
    <div
      className={st(classes.root, { mobile } as any)}
      data-hook={DATA_HOOKS.root}
    >
      <About group={group} updateProgress={updateProgress} />
      <GroupInfo group={group} />
      <GroupActivity group={group} />
      <GroupRules rules={rules} />
    </div>
  );

  return mobile ? renderMobile() : renderDesktop();
};

const enhance = compose(WithGroup, withTpaComponentsConfig);

export const AboutPage = enhance(AboutPageComponent);

export default AboutPage;

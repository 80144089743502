import { ContentConverter } from '../../../../common/ContentEditor/content/ContentConverter';
import { PluginTypeParser } from '../../../../common/ContentEditor/plugins/plugin-type-parser/PluginTypeParser';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';

export function getCustomTabBIData(_contentState: RawDraftContentState<any>) {
  const parser = new PluginTypeParser();
  return {
    filesCnt: parser.getFiles(_contentState).length,
    imgCnt: parser.getImages(_contentState).length,
    linksCnt: parser.getLinks(_contentState).length,
    videosCnt: parser.getVideos(_contentState).length,
    tabDescription: ContentConverter.parseTextFromRaw(_contentState),
  };
}

import React from 'react';

import { Grid } from 'wix-ui-tpa/Grid';
import { ApiTypes, memberWrapper } from '@wix/social-groups-api';

import { MemberCard } from '../../MemberCard/MemberCard';
import { st, classes } from './UsersJoinedModal.st.css';
import { TPAModalProps } from '../../../../../common/components/Modal/TPAModal';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { ModalV2 } from '../../../../../common/components/Modal/ModalV2';
import { FollowButton } from '../../FollowButton/FollowButton';
import { compose } from '../../../../../common/utils/compose';
import { withTheme, Theme } from '../../../../../common/context/theme';

interface UserJoinedModalProps extends TPAModalProps {
  members: ApiTypes.v1.GroupMemberResponse[];
}

type UserJoinedModalComponentProps = UserJoinedModalProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps &
  Theme &
  WithTranslation;

const UserJoinedModalComponent: React.FC<UserJoinedModalComponentProps> = (
  props,
) => {
  const { onRequestClose, isOpen, t, members, mobile, forceBlackAndWhite } =
    props;

  return (
    <ModalV2 onRequestClose={onRequestClose} isOpen={isOpen}>
      <ModalV2.Title>{t('groups-web.users-joined-popup.title')}</ModalV2.Title>
      <ModalV2.Content
        className={st(classes.contentWrapper, { bw: !!forceBlackAndWhite })}
      >
        {members.map((m, i) => {
          const { name, imageUrl } = memberWrapper(m);
          return (
            <div
              key={m.siteMemberId}
              className={st(classes.memberCardWrapper, { mobile } as any)}
            >
              <Grid.Item colSpan={1} rowSpan={1}>
                <MemberCard
                  forceBlackAndWhite={forceBlackAndWhite}
                  name={name!.nick || t('groups-web.member.anonymous')}
                  image={imageUrl}
                  withDivider={!mobile && i !== members.length - 1}
                  action={<FollowButton siteMemberId={m.siteMemberId!} />}
                />
              </Grid.Item>
            </div>
          );
        })}
      </ModalV2.Content>
    </ModalV2>
  );
};

const enhance = compose(
  withTranslation(),
  withSiteMembers,
  withTpaComponentsConfig,
  withTheme,
);

export const UserJoinedModal = enhance(
  UserJoinedModalComponent,
) as React.ComponentType<UserJoinedModalProps>;

import React from 'react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';

import { ModalProps, TPAModal } from '../Modal';
import { st, classes } from './SideDrawer.st.css';
import { hideBodyOverflow } from '../../utils/utils';

interface SideDrawerProps extends ModalProps {
  children?: React.ReactNode;
}

export const SideDrawer: React.FC<SideDrawerProps> = (props) => {
  hideBodyOverflow(props.isOpen, classes.overflowHidden);
  const { inFullScreen, skin, ...rest } = props;
  return (
    <TPAComponentsConsumer>
      {({ mobile }) => {
        return (
          <TPAModal
            inFullScreen={true}
            withCloseButton={false}
            {...rest}
            className={st(
              classes.root,
              { skin, mobile } as any,
              props.className,
            )}
          />
        );
      }}
    </TPAComponentsConsumer>
  );
};
SideDrawer.displayName = 'SideDrawer';

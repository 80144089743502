import React from 'react';
import { RemoveMemberDialog } from '../Dialogs/RemoveMemberDialog';
import { ChangeAdminRoleDialog } from '../Dialogs/AddOrRemoveAdminRole/ChangeAdminRoleDialog';
import { ModalProps } from '../../../../common/components/Modal';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { MembersActions } from '../../controllers/members/MembersActions';
import { ApiTypes } from '@wix/social-groups-api';
import { useGroupBI } from '../hooks/useGroupBI';
import { IGroupMember } from '../../../../common/api/model/GroupMember/IGroupMember';

export enum DialogType {
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  CHANGE_ADMIN_ROLE = 'CHANGE_ADMIN_ROLE',
  ANSWERS = 'ANSWERS',
}
interface Props
  extends Partial<ModalProps>,
    Pick<MembersActions, 'changeMemberRoleInGroup' | 'removeMembersFromGroup'> {
  member: IGroupMember;
  groupId: string;
  openDialogType: DialogType;
}

export const ActionModals: React.FC<Props> = ({
  member,
  openDialogType,
  onRequestClose,
  changeMemberRoleInGroup,
  removeMembersFromGroup,
  groupId,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const bi = useGroupBI();
  const memberId = member.getId();
  const image = member.getImageUrl();
  const name = member.getName(t('groups-web.member.anonymous'));
  const isGroupAdmin = member.isAdmin();

  const confirmMemberRemoval = () => {
    removeMembersFromGroup!(groupId!, [memberId!]);
    bi.memberTabAdminActionDone({
      site_member_id: memberId,
      action: 'remove_member',
    });
    onRequestClose?.();
  };
  const addAdminRole = () => {
    changeMemberRoleInGroup!(
      groupId!,
      memberId!,
      ApiTypes.v1.RoleInGroup.GROUP_ADMIN,
    );
    bi.memberTabAdminActionDone({
      site_member_id: memberId,
      action: 'set_admin',
    });
    onRequestClose?.();
  };

  const removeAdminRole = () => {
    changeMemberRoleInGroup!(
      groupId!,
      memberId!,
      ApiTypes.v1.RoleInGroup.GROUP_MEMBER,
    );
    bi.memberTabAdminActionDone({
      site_member_id: memberId,
      action: 'remove_admin',
    });
    onRequestClose?.();
  };

  const changeAdminRole = () => {
    if (!member) {
      return;
    }
    if (member.isAdmin()) {
      return removeAdminRole();
    }
    addAdminRole();
  };

  return (
    <>
      <RemoveMemberDialog
        isOpen={openDialogType === DialogType.REMOVE_MEMBER}
        memberFullName={name}
        onConfirmDeletion={confirmMemberRemoval}
        onRequestClose={onRequestClose!}
      />
      <ChangeAdminRoleDialog
        isOpen={openDialogType === DialogType.CHANGE_ADMIN_ROLE}
        name={name}
        image={image}
        isAdmin={isGroupAdmin}
        onChangeAdminRole={changeAdminRole}
        onRequestClose={onRequestClose!}
      />
    </>
  );
};

ActionModals.displayName = 'ActionModals';

import { MembersLabelTranslations } from '../../../../common/settings/groupMembersLabel';

export const MembersLabelWithCountDict: MembersLabelTranslations = {
  Members: 'groups-web.member',
  Students: 'groups-web.members.student',
  Coworkers: 'groups-web.members.coworker',
  Friends: 'groups-web.members.friend',
  Gamers: 'groups-web.members.gamer',
  Custom: 'groups-web.members.custom',
};

export function getMembersLabelWithCount(
  groupMemberLabel: any,
  count: number,
  t: any,
) {
  const translationKey = groupMemberLabel
    ? // @ts-expect-error
      MembersLabelWithCountDict[groupMemberLabel]
    : MembersLabelWithCountDict.Members;

  const label = translationKey
    ? t(translationKey, {
        count,
        formattedCount: count,
      })
    : `${count} ${groupMemberLabel}`;
  return label;
}

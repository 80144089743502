import React from 'react';
import classnames from 'classnames';
import { classes } from './NewPost.st.css';
import { ImagePluginIcon } from '../../icons/ImagePluginIcon';
import { VideoCameraIcon } from '../../icons/VideoCamera';
import { GiphyPluginIcon } from '../../icons/GiphyPluginIcon';

interface PluginsIconBarProps {
  className?: string;
  mobile?: boolean;
}

export const PluginsIconBar: React.FC<PluginsIconBarProps> = (props) => {
  const { className, mobile } = props;
  const defaultClassName = classnames(
    classes.fillPluginIcon,
    classes.pluginIcon,
    className,
  );
  return (
    <>
      <ImagePluginIcon
        width={19}
        height={19}
        className={defaultClassName}
        key="Image"
      />
      {!mobile && (
        <VideoCameraIcon height={19} className={defaultClassName} key="Video" />
      )}
      <GiphyPluginIcon
        width={19}
        height={19}
        className={classnames(
          classes.strokePluginIcon,
          classes.pluginIcon,
          className,
        )}
        key="Giphy"
      />
    </>
  );
};

PluginsIconBar.displayName = 'PluginsIconBar';

import { RawDraftContentState, RawDraftEntity } from '../../types';
import { PluginTypes } from '../pluginTypes';

export class PluginTypeParser {
  private readonly availableTypes = new Set<PluginTypes>(
    Object.values(PluginTypes),
  );
  private hasPluginOfType<T>(
    content: RawDraftContentState<T>,
    type: PluginTypes,
  ) {
    const entities = this.getEntities(content);
    return entities.some((entity) => {
      return entity.type === type;
    });
  }

  private getEntities<T>(content: RawDraftContentState<T>) {
    return Object.values(content.entityMap || {}) as RawDraftEntity<T>[];
  }

  hasGallery<T>(content: RawDraftContentState<T>) {
    return this.hasPluginOfType(content, PluginTypes.Gallery);
  }

  hasVideo(content: RawDraftContentState<any>) {
    return this.hasPluginOfType(content, PluginTypes.Video);
  }

  hasPolls(content: RawDraftContentState<any>) {
    return this.hasPluginOfType(content, PluginTypes.Poll);
  }

  getTypes(contents: RawDraftContentState<any>[]) {
    const types: PluginTypes[] = contents.reduce((flatMap, rawDraft) => {
      const entityTypes = this.getContentTypes(rawDraft);
      return flatMap.concat(entityTypes as any);
    }, []);
    return new Set(types);
  }

  getContentTypes(content: RawDraftContentState<any>): PluginTypes[] {
    return this.getEntities(content)
      .map((entity) => entity.type)
      .filter((type: any) => this.availableTypes.has(type)) as PluginTypes[];
  }

  getEntitiesByType(content: RawDraftContentState<any>, type: PluginTypes) {
    const entities = this.getEntities(content);
    return entities.filter((entity) => {
      return entity.type === type;
    });
  }

  getVideos(content: RawDraftContentState<any>) {
    return this.getEntitiesByType(content, PluginTypes.Video);
  }

  getFiles(content: RawDraftContentState<any>) {
    return this.getEntitiesByType(content, PluginTypes.FileUpload);
  }

  getImages(content: RawDraftContentState<any>) {
    return this.getEntitiesByType(content, PluginTypes.Image);
  }

  getLinks(content: RawDraftContentState<any>) {
    return this.getEntitiesByType(content, PluginTypes.Link);
  }
}

import React from 'react';
import { MembersRequestState } from '../../../controllers/members/MembersRequestState';
import { RequestState } from '@wix/social-groups-api/dist/src/services/RequestState';
import { Spinner } from '../../../../../common/components/Spinner';
import { Members, MembersProps } from './Members';
import { InviteProps, Invites } from './Invites';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { EmptyState } from '../../EmptyState/EmptyState';
import { isValidEmail } from '../../../../../common/utils/utils';
import { classes } from './AddMembersModal.st.css';
import { Theme, withTheme } from '../../../../../common/context/theme';

interface Props
  extends MembersRequestState,
    MembersProps,
    Omit<InviteProps, 'email'> {
  searchQuery: string;
  isSecret: boolean;
}

function getEmptyStateKey(isSecret: boolean, searchQuery: string) {
  if (isSecret) {
    return searchQuery
      ? 'groups-web.secret-group.members.empty-search-match.caption'
      : 'groups-web.discussion.members.empty';
  } else {
    return searchQuery
      ? 'group-web.members.empty-search-match.caption'
      : 'group-web.members.empty.caption';
  }
}
export const AddMembersComponent: React.FC<Props & Theme> = ({
  membersRequest,
  members,
  onSelect,
  searchQuery,
  invite,
  done,
  isSecret,
  selectedIds,
  selectAll,
  excludedIds,
  forceBlackAndWhite,
}) => {
  const { t } = useTranslation();

  const addAllPending =
    membersRequest &&
    membersRequest.addAllNonGroupMembersToGroup === RequestState.PENDING;
  const queryIsPending =
    membersRequest &&
    membersRequest.queryNonGroupMembers === RequestState.PENDING;
  if (!members || !members.length) {
    const email =
      !isSecret && isValidEmail(searchQuery.trim()) ? searchQuery : undefined;

    return email ? (
      <Invites email={email} invite={invite} done={done} />
    ) : queryIsPending ? (
      <Spinner
        offset="M"
        forceBlackAndWhite={forceBlackAndWhite}
        label={t('groups-web.loading')}
      />
    ) : (
      <EmptyState
        className={classes.emptyState}
        transparent
        title={searchQuery ? undefined : t('group-web.members.empty')}
        content={t(getEmptyStateKey(isSecret, searchQuery))}
        boxProps={{
          withTopBottomBorders: false,
          withSideBorders: false,
        }}
      />
    );
  }
  return addAllPending ? (
    <Spinner
      offset="M"
      forceBlackAndWhite={forceBlackAndWhite}
      label={t('groups-web.loading')}
    />
  ) : (
    <Members
      members={members}
      onSelect={onSelect}
      loading={queryIsPending}
      selectedIds={selectedIds}
      excludedIds={excludedIds}
      selectAll={selectAll}
    />
  );
};

export const AddMembers = withTheme(
  AddMembersComponent,
) as React.ComponentType<Props>;
AddMembers.displayName = 'AddMembers';

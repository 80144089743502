import { useUserActions } from '../../UserStatus/useUserActions';
import { ActionType } from '../../UserStatus/ActionType';

export function usePolls() {
  const { initAction } = useUserActions();
  return {
    onBeforeVote() {
      return initAction(ActionType.CHECK_GROUP_MEMBERSHIP);
    },
  };
}

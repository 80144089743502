import React from 'react';

import { classes } from './EmptyStates.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { EmptyState, EmptyStateProps } from '../../EmptyState/EmptyState';
import { EFilterKeys } from '../../../types/EFilterKeys';
import { useUserActions } from '../../UserStatus/useUserActions';
import { ActionType } from '../../UserStatus/ActionType';

interface EmptyStatesProps extends Partial<EmptyStateProps> {
  origin?: string;
  emptyState?: boolean;
}

export const EmptyStates: React.FC<EmptyStatesProps> = ({
  origin,
  emptyState,
  dataHook,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isMobile: mobile } = useEnvironment();
  const {
    feed: { feedFilters, applyFeedFilters },
  } = React.useContext(GroupContext);
  const { initAction } = useUserActions();

  const emptyFilters = !Object.values(feedFilters).some((value) => !!value);

  const openNewPostModal = () => {
    return initAction(ActionType.WRITE_POST, origin);
  };
  // No posts yet
  if (emptyFilters || emptyState) {
    return (
      <EmptyState
        dataHook={dataHook}
        className={classes.root}
        title={t('groups-web.discussion.first.title')}
        content={t('groups-web.discussion.first.description')}
        button={{
          label: t('groups-web.discussion.first.button'),
          onClick: openNewPostModal,
        }}
        boxProps={{
          withSideBorders: !mobile,
        }}
        {...rest}
      />
    );
  }

  // Post by id not found
  if (feedFilters[EFilterKeys.ID]) {
    return (
      <EmptyState
        dataHook={dataHook}
        className={classes.root}
        title={t('groups-web.discussion.post-not-found.title')}
        content={t('groups-web.discussion.post-not-found.subtitle')}
        button={{
          label: t('groups-web.discussion.post-not-found.back-to-discussion'),
          onClick: resetFilters,
        }}
        boxProps={{
          withSideBorders: !mobile,
        }}
      />
    );
  }

  // Filter results empty
  return (
    <EmptyState
      dataHook={dataHook}
      className={classes.root}
      title={t('groups-web.discussion.filters.empty-result.title')}
      content={t('groups-web.discussion.filters.empty-result.subtitle')}
      button={{
        label: t('groups-web.discussion.filters.empty-result.button'),
        onClick: openNewPostModal,
      }}
      boxProps={{
        withSideBorders: !mobile,
      }}
    />
  );

  function resetFilters() {
    applyFeedFilters();
  }
};

EmptyStates.displayName = 'EmptyStates';

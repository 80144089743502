import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { EmptyState } from '../EmptyState/EmptyState';

export const EventsEmptyState: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('groups-web.events.empty.title')}
      content={t('groups-web.events.empty.subtitle')}
    />
  );
};

EventsEmptyState.displayName = 'EventsEmptyState';

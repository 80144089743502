import React from 'react';
import { CommonErrorProps } from './CommonErrorProps';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { EmptyState } from '../EmptyState/EmptyState';

export const Error403: React.FC<CommonErrorProps> = (props) => {
  const { action } = props;
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('groups-web.discussion.error-403.title')}
      content={t('groups-web.discussion.error-403.content')}
      button={{
        label: t('groups-web.discussion.error-403.cta'), // CLOSE SCREEN?
        onClick: () => action(),
      }}
    />
  );
};

Error403.displayName = 'Error403';

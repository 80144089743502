import { Member } from '../Member/Member';
import { IPendingMember } from '../../../../components/Group/controllers/joinGroupRequests/IPendingMember';

export class GroupPendingMember extends Member {
  joinGroupRequest: IPendingMember['joinGroupRequest'];
  constructor(pendingMember: IPendingMember) {
    super(pendingMember.member);
    this.joinGroupRequest = pendingMember.joinGroupRequest;
  }

  getRequestDate() {
    return new Date(this.joinGroupRequest.createdDate!);
  }
}

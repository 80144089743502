import React from 'react';
import { ApiTypes } from '@wix/social-groups-api';
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { classes, st } from './AddMembersModal.st.css';
import { MemberCard } from '../../MemberCard/MemberCard';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../../common/components/Spinner';
import { Theme, withTheme } from '../../../../../common/context/theme';

export interface MembersProps {
  members: ApiTypes.v1.SiteMemberProfileResponse[];
  updating?: boolean;
  selectedIds: Set<string>;
  excludedIds: Set<string>;
  onSelect(selectedId: string, selected: boolean): void;
  loading?: boolean;
  selectAll?: boolean;
}

const MembersComponent = ({
  members,
  updating,
  onSelect,
  selectedIds,
  excludedIds,
  loading,
  selectAll,
  forceBlackAndWhite,
}: MembersProps & Theme) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const isMemberSelected = (id: string) => {
    return (selectAll && !excludedIds.has(id)) || selectedIds.has(id);
  };
  return (
    <>
      {members.map(
        (member: ApiTypes.v1.SiteMemberProfileResponse, i: number) => {
          const { name, imageUrl } = memberWrapper(member);
          const memberId = member.siteMemberId;
          return (
            <div
              className={st(classes.memberCardWrapper, {
                mobile: isMobile,
              } as any)}
              key={member.siteMemberId}
            >
              <MemberCard
                forceBlackAndWhite={forceBlackAndWhite}
                onSelect={(selected) => onSelect(memberId!, selected)}
                allowSelect={true}
                selected={isMemberSelected(memberId!)}
                name={name!.nick || t('groups-web.member.anonymous')}
                image={imageUrl}
                withDivider={!isMobile && i !== members.length - 1}
                updating={updating}
              />
            </div>
          );
        },
      )}
      {loading ? (
        <Spinner
          offset="M"
          forceBlackAndWhite={forceBlackAndWhite}
          label={t('groups-web.loading')}
        />
      ) : null}
    </>
  );
};
export const Members = withTheme(
  MembersComponent,
) as React.ComponentType<MembersProps>;

import React from 'react';

export const ShareIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M15.2 6.4l4.5 4.7-4.5 4.7V14.1l-.8-.2c-.8-.2-1.5-.3-2.3-.3-2.9 0-5.7 1.2-7.7 3.2C6.6 8.5 13.3 8 14.2 8h.9V6.4h.1zm-.9.7C12.2 7.2 3.7 8.6 3 21c1.5-3.8 5.1-6.3 9.2-6.3.7 0 1.3.1 2.1.2v3.3l6.7-7.1L14.3 4v3.1z"
      strokeWidth={0.1}
      fillRule="nonzero"
    />
  </svg>
);

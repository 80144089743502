import React, { useState } from 'react';
import { classes } from '../Members.st.css';

import { AdminActions } from './AdminActions';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ThreeDots, ThreeDotsActions } from '../../ThreeDots/ThreeDots';
import { useGroupBI } from '../../hooks/useGroupBI';

export interface MemberActionsProps extends AdminActions {
  isGroupAdmin: boolean;
  isSiteAdmin: boolean;
  hasAnswers: boolean;
  memberId: string;
}

type Props = MemberActionsProps;

export const MemberActions: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const bi = useGroupBI();
  const { memberId } = props;
  const memberTabAdminActionClick = bi.memberTabAdminActionClick({
    site_member_id: memberId,
  });
  const viewAnswers = (e?: any) => {
    const { onViewAnswers } = props;
    e && e.stopPropagation();
    setOpen(false);
    onViewAnswers(memberId);
    memberTabAdminActionClick('view_answers');
  };

  const removeAdmin = (e?: any) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    setOpen(false);
    props.onChangeAdmin(memberId);
    memberTabAdminActionClick('remove_admin');
  };

  const addAdmin = (e?: any) => {
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    const { onChangeAdmin } = props;
    setOpen(false);
    onChangeAdmin(memberId);
    memberTabAdminActionClick('set_admin');
  };

  const removeMember = (e?: any) => {
    const { onRemove } = props;
    // wix-ui-tpa `ActionsMenuLayoutItem` lost event if 'keydown' 🤷‍
    e && e.stopPropagation();
    setOpen(false);
    onRemove(memberId);
    memberTabAdminActionClick('remove_member');
  };

  function getMemberMenuItems(): ThreeDotsActions[] {
    const { hasAnswers, isGroupAdmin, isSiteAdmin } = props;
    const items: ThreeDotsActions[] = [];
    if (hasAnswers) {
      items.push({
        content: t('groups-web.members.more-actions.view-answers.menu-item'),
        onClick: viewAnswers,
      });
    }
    if (isGroupAdmin && !isSiteAdmin) {
      items.push({
        content: t(
          'groups-web.members.more-actions.remove-admin-role.menu-item',
        ),
        onClick: removeAdmin,
      });
    }
    if (!isGroupAdmin) {
      items.push({
        content: t('groups-web.members.more-actions.add-admin-role.menu-item'),
        onClick: addAdmin,
      });
    }
    items.push({
      content: t(
        'groups-web.members.more-actions.remove-member-from-group.menu-item',
      ),
      onClick: removeMember,
    });
    return items;
  }

  const onOpen = (e?: any) => {
    e && e.stopPropagation();
    setOpen(true);
  };
  return (
    <ThreeDots
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      onOpen={onOpen}
      items={getMemberMenuItems()}
      iconClassName={classes.threeDotsIcon}
      className={classes.threeDotsMenu}
    />
  );
};

MemberActions.displayName = 'MemberActions';

import { useEffect } from 'react';

export function useRedirect({ slug, id }: { slug: string; id: string }) {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (!id || !slug) {
      return;
    }
    const searchString = `/${id}/`;
    if (window.location.pathname.includes(searchString)) {
      const href = window.location.href.replace(searchString, `/${slug}/`);
      window.history.replaceState(null, '', href);
    }
  }, [id]);
}

import React from 'react';
import classname from 'classnames';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './RepositionControls.st.css';
import { classes as commonClasses } from '../SuperHeroImage.st.css';
import { Reposition as RepositionIcon } from '../../Icons/Reposition';
import { compose } from '../../../utils/compose';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../../components/Group/contexts/TPAComponent/withTpaComponentsConfig';

interface RepositionControlsProps {
  loading: boolean;
  onChange: (movementY: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

interface RepositionControlsState {
  dragging: boolean;
  previousTouch: Touch | null;
}

type Props = RepositionControlsProps &
  WithTranslation &
  WithTpaComponentsConfigProps;

class RepositionControlsComponent extends React.Component<
  Props,
  RepositionControlsState
> {
  state = {
    dragging: false,
    previousTouch: null,
  };

  render() {
    const { dragging } = this.state;
    const { onSave, onCancel, loading, t, mobile } = this.props;
    return (
      <div
        className={st(classes.root, { dragging }, commonClasses.layout)}
        onMouseDown={this.startRepositioning}
        onTouchStart={this.startRepositioning}
        onMouseUp={this.stopRepositioning}
        onTouchEnd={this.stopRepositioning}
        onMouseMove={this.handlePositionChange}
        onTouchMove={this.handlePositionChange}
        onMouseLeave={this.stopRepositioning}
      >
        <div className={classes.repositionButtonCell}>
          <Button
            upgrade
            className={classname(
              classes.repositionButton,
              commonClasses.greyButton,
            )}
            prefixIcon={<RepositionIcon />}
          >
            {t('groups-web.reposition.drag-cta')}
          </Button>
        </div>
        {!mobile && (
          <div className={classes.saveCancelCell}>
            <Button
              upgrade
              onClick={onCancel}
              onMouseDown={this.stopPropagation}
              className={classes.cancelButton}
              priority={PRIORITY.basicSecondary}
            >
              {t('groups-web.reposition.cancel')}
            </Button>
            <Button
              upgrade
              onClick={onSave}
              onMouseDown={this.stopPropagation}
              className={classes.saveButton}
              priority={PRIORITY.basic}
            >
              {t(
                loading
                  ? 'groups-web.reposition.saving'
                  : 'groups-web.reposition.save',
              )}
            </Button>
          </div>
        )}
      </div>
    );
  }

  private handlePositionChange = (event: any) => {
    const { dragging, previousTouch } = this.state;
    const { onChange, loading } = this.props;
    if (!dragging || loading) {
      return;
    }

    const currentTouch = event.touches && event.touches[0];
    if (previousTouch && currentTouch) {
      const movementY = currentTouch.pageY - (previousTouch as any).pageY;
      this.setState({
        previousTouch: currentTouch,
      });
      onChange(movementY);
    } else {
      onChange(event.movementY);
    }
  };

  private stopPropagation = (event: any) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({
      previousTouch: null,
    });
  };

  private startRepositioning = (event: any) => {
    if (this.props.loading) {
      return;
    }
    this.stopPropagation(event);
    this.setState({
      dragging: true,
    });
    if (event && event.touches && event.touches[0]) {
      this.setState({
        previousTouch: event.touches[0],
      });
    }
  };

  private stopRepositioning = () => {
    this.setState({
      dragging: false,
    });
  };
}

const enhance = compose(withTranslation(), withTpaComponentsConfig);
export const RepositionControls = enhance(
  RepositionControlsComponent,
) as React.ComponentType<RepositionControlsProps>;

import React from 'react';

import { Text } from 'wix-ui-tpa/Text';
import { Tag, SIZE } from 'wix-ui-tpa/Tag';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';

import * as discussionStyles from '../Discussion.st.css';
import { classes } from './FeedTopicsWidget.st.css';
import {
  useBi,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { Box } from '../../../../../common/components/Box/Box';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { TextButton } from '../../../../../common/components/TextButton/TextButton';
import { ITopic } from '../../../types/ITopic';
import { EFilterKeys } from '../../../types/EFilterKeys';
import { groupFeedTopicsSelectTopicInFeed } from '@wix/bi-logger-groups/v2';

const FeedTopicsWidgetComponent: React.FC<WithTranslation> = ({ t }) => {
  const {
    feed: { feedTopics, applyFeedFilters },
    group: { groupId },
  } = React.useContext(GroupContext);
  const { mobile } = React.useContext(TPAComponentsContext);
  const MAX_TO_SHOW = React.useMemo(() => (mobile ? 4 : 10), [mobile]);

  const [collapsed, setCollapsed] = React.useState(true);
  const bi = useBi();
  if (!feedTopics || !feedTopics.length) {
    return null;
  }
  const topics = collapsed ? feedTopics.slice(0, MAX_TO_SHOW) : feedTopics;

  return (
    <Box article>
      <Text className={discussionStyles.classes.widgetHeader}>
        {t('groups-web.discussion.topics.widget.title')}
      </Text>
      <ul className={classes.list}>
        {topics.map((topic) => (
          <Tag
            key={topic.id}
            className={classes.tag}
            size={SIZE.small}
            onClick={() => applyFilter(topic)}
          >
            {`${topic.displayName} (${topic.count})`}
          </Tag>
        ))}
      </ul>
      {feedTopics.length > MAX_TO_SHOW ? (
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={toggleCollapsed}
        >
          {collapsed
            ? t('groups-web.discussion.topics.widget.more')
            : t('groups-web.discussion.topics.widget.less')}
        </TextButton>
      ) : null}
    </Box>
  );

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function applyFilter(topic: ITopic) {
    applyFeedFilters({
      [EFilterKeys.TOPICS]: topic.id,
    });
    bi.report(
      groupFeedTopicsSelectTopicInFeed({
        groupId: groupId!,
        origin: 'feed_topics_section',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }
};

export const FeedTopicsWidget = withTranslation()(
  FeedTopicsWidgetComponent,
) as any;
